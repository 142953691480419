import { Layout } from "@brunas/dashboard";
import { FC, useContext, useState } from "react";
import MainMenu from 'components/MainMenu';
import MainToolbar from "components/MainToolbar";
import { UserDataContext } from "lib/Auth/UserDataProvider";
import { Avatar } from '@brunas/dashboard';
import { LinearProgress, ListItemIcon, ListItemButton, ListItemText } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useTranslation } from "react-i18next";
import Login from "./Login";
import useAuthService from "services/useAuthService";

import logo from 'assets/img/brunas-logo-transparent.png';



const AuthorizedLayout: FC = () => {
    const authService = useAuthService()
    const { userData, refreshData, loading } = useContext(UserDataContext)

    const { t } = useTranslation("layout")

    const [loginLoading, setLoginLoading] = useState<boolean>(false)

    if (loading) {
        return <LinearProgress />
    }

    const login = async (username: string, password: string) => {
        setLoginLoading(true)
        if (await authService.login(username, password)) {
            refreshData()
        } //TODO login error
        setLoginLoading(false)
    }

    const logout = async () => {
        if (await authService.logout()) {
            refreshData()
        }
    }


    if (!userData) {
        return <Login loading={loginLoading} logo={<img src={logo} alt="" />} onSubmit={values => login(values.login, values.password)} />
    }


    const menuItems = [
        {
            path: "/",
            icon: DashboardIcon,
            name: t("MENU_FORM_LIST")
        }
    ]

    return <Layout
        logo={<img src={logo} alt="" />}
        menu={<MainMenu items={menuItems} />}
        toolbar={<MainToolbar appName={t("PAGE_TITLE")} />}
        userMenu={<ListItemButton onClick={logout}>
            <ListItemIcon>
                <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
                {t('MENU_LOGOUT')}
            </ListItemText>
        </ListItemButton>
        }
        avatar={<Avatar firstName={userData.firstName} lastName={userData.lastName} />}
        contentPadding={2}
    />
};

export default AuthorizedLayout;