import { FC, useContext } from 'react';
import { useLocation } from 'react-router';

import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel, MenuItem as MuiMenuItem } from '@mui/material';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import { ClientContext } from 'lib/Auth/UserDataProvider';
import { useTranslation } from 'react-i18next';
import { useLayout } from '@brunas/dashboard';

export interface MenuItem {
  path: string
  icon: FC
  name: string
}

const MainMenu: FC<{ items: MenuItem[] }> = ({ items }) => {
  const { pathname } = useLocation();
  const { client, setClient, clientList } = useContext(ClientContext)
  const { t } = useTranslation("menu")

  const {mainMenuOpen} = useLayout()

  return (
    <List>
      <ListItem>
        {clientList.length > 1 && mainMenuOpen && <FormControl fullWidth>
          <InputLabel>
            {t("CLIENT_FIELD_LABEL")}
          </InputLabel>
          <Select label={t("CLIENT_FIELD_LABEL")} fullWidth value={client ? client.id : ""} onChange={e => {
            const result = clientList.find(item => item.id === e.target.value)
            if (!result) {
              return
            }
            setClient(result)
          }}>
            {clientList.map((client, i) => <MuiMenuItem key={i} value={client.id}>{client.name}</MuiMenuItem>)}
          </Select>
        </FormControl>}

      </ListItem>
      {items.map((item, i) => <ListItemButton key={i} href={item.path} component={Link} selected={pathname === item.path}>
        <ListItemIcon>
          <item.icon />
        </ListItemIcon>

        <ListItemText primary={item.name} />
      </ListItemButton>)}
    </List>
  );
};

export default MainMenu;
