import { Button, Dialog, Box, Typography, IconButton, CircularProgress, Tooltip } from "@mui/material"
import { FC, useRef, useState } from "react"
import { AnswerError, ErrorFieldType, PartialAnswerError } from "../FormView"
import SimpleQuestionFieldWrapper from "./SimpleQuestionFieldWrapper"
import { Answer, ElementConfig, WebCam } from "lib/Form/Models"
import Camera, { FACING_MODES } from "react-html5-camera-photo"
import 'react-html5-camera-photo/build/css/index.css';
import useFilesService from "services/useFilesService"
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from "react-i18next"
import { isFileImage, isUrlImage } from "lib/Utils/is-image"

type Props = {
    element: ElementConfig,
    error: AnswerError | null,
    answerData: Answer | null,
    updateValue: (value: any, clearFields: ErrorFieldType[] | null) => void
}

export const ValidateFileField = (answerData: Answer | null, elementConfig: ElementConfig, t: any): PartialAnswerError[] => {

    const value: string = answerData && typeof answerData.value === "string" ? answerData.value : ""

    if (elementConfig.required) {
        if (!value) {
            return [{
                field: null,
                error: t("REQUIRED_FIELD_LEFT_EMPTY_ERROR")
            }]
        }
    }

    return []
}

const FileField: FC<Props> = ({ element, error, answerData, updateValue }) => {

    const filesService = useFilesService()

    const { t } = useTranslation("form-view")

    const [cameraOpen, setCameraOpen] = useState<boolean>(false)
    const [cameraError, setCameraError] = useState<boolean>(false)
    const [previewUrl, setPreviewUrl] = useState<string | null>(answerData?.value ? answerData.value : null)
    const [previewDialog, setPreviewDialog] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)
    const [uploadError, setUploadError] = useState<boolean>(true)
    const [isImage, setIsImage] = useState<boolean>(typeof answerData?.value === "string" ? isUrlImage(answerData.value) : false)

    const version = useRef<number>(1)

    const takePhoto = () => {
        setCameraError(false)
        setCameraOpen(true)
    }

    const onPhotoCapture = async (data: string) => {
        version.current++
        const versionCheck = version.current
        setCameraOpen(false)
        setPreviewUrl(data)
        setUploading(true)
        setUploadError(false)
        setIsImage(true)
        const uplaodedPhoto = await filesService.uploadContent("photo.jpg", data)
        if (versionCheck === version.current) {
            setPreviewUrl(uplaodedPhoto)
            updateValue(uplaodedPhoto, null)
            setUploading(false)
        }
    }

    const onFileSelect = async (file: File) => {
        version.current++
        const versionCheck = version.current
        setUploadError(false)
        setPreviewUrl(URL.createObjectURL(file))
        setUploading(true)
        setIsImage(isFileImage(file))
        const uplaodedPhoto = await filesService.uploadFile(file)
        if (versionCheck === version.current) {
            setPreviewUrl(uplaodedPhoto)
            updateValue(uplaodedPhoto, null)
            setUploading(false)
        }
    }

    const clear = () => {
        setUploadError(false)
        updateValue(null, [])
        setPreviewUrl(null)
        setUploading(false)
    }

    return <>
        <SimpleQuestionFieldWrapper element={element} error={error}>

            <Box sx={{ display: "flex", gap: 2, alignItems: "center", marginTop: 1 }}>
                {previewUrl ?
                    <>
                        {isImage ?
                            <>
                                <img alt="" src={previewUrl} onClick={() => setPreviewDialog(true)} style={{ maxHeight: 80, opacity: uploading ? 0.5 : 1, cursor: uploading ? "initial" : "pointer" }} />

                                <Dialog open={!uploading && previewDialog} onClose={() => setPreviewDialog(false)}>
                                    <img alt="" src={previewUrl} />
                                </Dialog>
                            </> :
                            <Button variant="text" LinkComponent={"a"} download href={previewUrl} startIcon={<InsertDriveFileIcon />}>{t("FILE_FIELD_ATTACHED_FILE")}</Button>
                        }

                        {uploading && <CircularProgress />}
                        {uploadError && <Tooltip title="TODO">
                            <WarningIcon color="error" />
                        </Tooltip>}
                        <IconButton onClick={clear}><DeleteIcon /></IconButton>
                    </>
                    :
                    <>
                        <Button variant="outlined" component="label" startIcon={<UploadFileIcon />}>
                            {t("FILE_SELECT_FROM_STORAGE")}
                            <input onChange={e => {
                                const files = e.target.files
                                if (files && files.length > 0) {
                                    const file = files[0]
                                    onFileSelect(file)
                                }
                            }}
                                type="file"
                                hidden
                            />
                        </Button>
                        <Button variant="outlined" startIcon={<CameraAltIcon />} onClick={takePhoto}>{t("FILE_TAKE_PHOTO")}</Button>
                    </>
                }
            </Box>
            {cameraError ? <Typography color="error">{t("CAMERA_ERROR")}</Typography> : <></>}
        </SimpleQuestionFieldWrapper>
        {cameraOpen && !cameraError && <Dialog maxWidth={false}
            open={cameraOpen}
            onClose={() => setCameraOpen(false)}
        >
            <Camera idealFacingMode={element.answerConfig?.camera === WebCam.Back ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER} onTakePhoto={onPhotoCapture} onCameraError={error => {
                setCameraError(true)
                setCameraOpen(false)
            }} />
        </Dialog>
        }
    </>
}

export default FileField