import { CountRequest, ListRequest } from "@brunas/react-mui-datatable"
import { Form, Answer, FormAnswer, AnswerTableItem, FormAnswerStatusEnum } from "lib/Form/Models"
import AuthorizedAxiosInstance from "./AuthorizedAxiosInstance"
import { FormAnswersService } from "lib/Form/FormAnswersService"
import ExtendedAxios from "./ExtendedAxios"


type Response<T> = {
    status: number,
    errorCode: string | null,
    errorMessage: string | null,
    data: T
}

export class FormAnswersApiService implements FormAnswersService {
    deleteAnswer(formId: string, answerId: string): Promise<null> {
        return AuthorizedAxiosInstance.delete<Response<null>>(`${this.host}/${formId}/answers/${answerId}`).then(value => value.data.data)
    }
    
    changeStatus(formId: string, answerId: string, status: FormAnswerStatusEnum): Promise<null> {
        return AuthorizedAxiosInstance.put<Response<null>>(`${this.host}/${formId}/answers/${answerId}/status?status=${status}`).then(value => value.data.data)
    }


    submitAnswer(id: string, answers: Answer[]): Promise<boolean> {
        const result = ExtendedAxios.post<Response<boolean>>(`${this.host}/${id}/submit`, answers).then(value => !!value.data.data)
        return result
    }


    getAnswer(formId: string, answerId: string): Promise<FormAnswer | null> {
        const result = AuthorizedAxiosInstance.get<Response<FormAnswer | null>>(`${this.host}/${formId}/answers/${answerId}`).then(value => value.data.data)
        return result
    }
    getDatatableList(request: ListRequest, config: Form): Promise<AnswerTableItem[]> {
        const result = AuthorizedAxiosInstance.post<Response<AnswerTableItem[]>>(`${this.host}/${config.id}/answers/datatable`, request).then(value => value.data.data)
        return result
    }
    getDatatableCount(request: CountRequest, config: Form): Promise<number> {
        const result = AuthorizedAxiosInstance.post<Response<number>>(`${this.host}/${config.id}/answers/datatable/count`, request).then(value => value.data.data)
        return result
    }
    host: string = "/api/forms";
}

export default new FormAnswersApiService()