const locale = {
    //Editing statuses
    STATUS_SAVED: "Išsaugota",
    STATUS_SAVING: "Saugojama...",
    STATUS_EDITING: "Redaguojama...",
    STATUS_INCOMPLETE_DATA: "Trūksta informacijos arba formos laukų",

    FORM_TAB_INFO: "Informacija",
    FORM_TAB_ANSWERS: "Atsakymai",
    FORM_TAB_STRUCTURE: "Formos laukai",

    //Form fields
    ELEMENT_NAME_FIELD: "Klausimas",

    ELEMENT_TITLE_FIELD: "Antraštė",
    ELEMENT_ROW_COUNT_FIELD: "Eilučių skaičius",
    ELEMENT_DESCRIPTION_FIELD: "Aprašymas",
    ELEMENT_REQUIRED_FIELD: "Būtina įvesti?",
    ELEMENT_SHOW_IN_TABLE_FIELD: "Rodyti atsakymų lentelėje",
    FORM_NAME_FIELD: "Pavadinimas",
    FORM_DESCRIPTION_FIELD: "Aprašymas",
    FORM_SUCCESS_TEXT_FIELD: "Sėkmingo užpildymo pranešimas",
    ANSWER_VALUE_PLACEHOLDER: "Įveskite reikšmę",
    ANSWER_NEW_VALUE_PLACEHOLDER: "Pridėti naują reikšmę",
    ANSWER_VALUES_TITLE: "Galimi pasirinkimai",

    //ELEMENT type names
    ELEMENT_TYPE_TEXT: "Tekstas",
    ELEMENT_TYPE_DROPDOWN: "Pasirinkimas",
    ELEMENT_TYPE_RADIO: "Pasirinkimas iš kelių",
    ELEMENT_TYPE_CHECKBOX: "Varnelės",
    ELEMENT_TYPE_FILE: "Failas",
    ELEMENT_TYPE_DATE: "Data",
    ELEMENT_TYPE_EMAIL: "El. paštas",
    ELEMENT_TYPE_HEADER: "Antraštė",
    ELEMENT_TYPE_PAGE_BREAK: "Puslapio lūžis",
    ELEMENT_TYPE_EMPLOYMENT_HISTORY: "Darboviečių istorija",

    //Form exit
    FORM_EDITED_EXIT_CONFIRM_TEXT: "Yra neišsaugotų pakeitimų. Ar tikrai norite išeiti?",
    FORM_EDITED_EXIT_CONFIRM_YES: "Taip",
    FORM_EDITED_EXIT_CONFIRM_NO: "Ne",

    //Camera
    CAMERA_FRONT: "Prikinė",
    CAMERA_BACK: "Galinė",
    CAMERA: "Kamera nuotraukai",

    //Delete element
    DELETE_CONFIRM_MESSAGE: "Ar tikrai trinti?",
    DELETE_CONFIRM_YES: "Taip",
    DELETE_CONFIRM_NO: "Ne",
};


export default locale