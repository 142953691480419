import { Button, Box, LinearProgress } from '@mui/material';
import Section from 'components/Section';
import { Form, ElementConfig, ElementTypeEnum, Answer, EmployerAnswerItem } from 'lib/Form/Models';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import EmailField, { ValidateEmailField } from './fields/EmailField';
import DropdownField, { ValidateDropdownField } from './fields/DropdownField';
import RadioField, { ValidateRadioField } from './fields/RadioField';
import CheckboxField, { ValidateCheckboxField } from './fields/CheckboxField';
import DateField, { ValidateDateField } from './fields/DateField';
import TextField, { ValidateTextField } from './fields/TextField';
import FileField, { ValidateFileField } from './fields/FileField';
import EmploymentHistoryField, { ValidateEmploymentHistoryField, isAnswerEmpty } from './fields/EmploymentHistoryField';
import useFormService from 'services/useFormService';
import useFormAnswersService from 'services/useFormAnswersService';
import ContentWrapper from 'components/ContentWrapper';

export type ErrorFieldType = string | null
export type AnswerError = {
  elementId: string,
  error: string,
  field: ErrorFieldType
}

export type PartialAnswerError = {
  error: string,
  field: ErrorFieldType
}



const prepareAnswers = (answers: Answer[]): Answer[] => {
  return answers.map(answer => {
    if (answer.elementType === ElementTypeEnum.EmploymentHistory) {
      answer.value = answer.value.filter((item: EmployerAnswerItem) => !isAnswerEmpty(item))
    }
    return answer
  })
}

const FormView: FC = () => {
  const { t } = useTranslation("form-view") //i18n
  const { id } = useParams() //form id 
  const [step, setStep] = useState<number>(0) //current form step
  const [form, setForm] = useState<Form | null>(null) //form data
  const [stepErrors, setStepErrors] = useState<AnswerError[]>([])
  const [answers, setAnswers] = useState<Answer[]>([])

  const [postSubmit, setPostSubmit] = useState<boolean>(false)
  const formsService = useFormService()
  const formAnswersService = useFormAnswersService()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  //Retrieves form data from service
  useEffect(() => {
    const fetchForm = async () => {
      if (id && formsService) {
        setForm(await formsService.getForm(id))
      }
    }
    fetchForm()
  }, [formsService, id])


  if (!form || isSubmitting) {
    return <LinearProgress />
  }


  if (postSubmit) {
    return <ContentWrapper><Section>{form.successText ? form.successText : t("FORM_DEFAULT_SUCCESS_MESSAGE")}</Section></ContentWrapper>
  }


  const submitForm = async () => {
    if (id && formAnswersService && validate()) {
      setIsSubmitting(true)
      if (await formAnswersService.submitAnswer(id, prepareAnswers(answers))) {
        setPostSubmit(true)
      }
      setIsSubmitting(false)
    }
  }



  const splitData: ElementConfig[][] = [[]];
  let splitIndex = 0;

  //Separating form elements into steps by ElementTypeEnum.PAGE_BREAK elements
  form.elements.forEach(element => {
    if (element.type === ElementTypeEnum.PageBreak) {
      splitIndex++
      return
    }

    if (!splitData[splitIndex]) {
      splitData[splitIndex] = []
    }

    splitData[splitIndex].push(element)
  })

  //Recalculates errors for all current step form elements (returns false if any errors found)
  const validate = (): boolean => {
    let success = true
    splitData[step].forEach(element => {
      const answer = getAnswer(element.id)
      if (!validateElement(element, answer)) {
        success = false
      }
    })

    return success
  }

  const getErrors = (elementConfig: ElementConfig, answer: Answer | null): PartialAnswerError[] => {
    switch (elementConfig.type) {
      case ElementTypeEnum.EmploymentHistory:
        return ValidateEmploymentHistoryField(answer, elementConfig, t)
      case ElementTypeEnum.Email:
        return ValidateEmailField(answer, elementConfig, t)
      case ElementTypeEnum.Text:
        return ValidateTextField(answer, elementConfig, t)
      case ElementTypeEnum.Radio:
        return ValidateRadioField(answer, elementConfig, t)
      case ElementTypeEnum.Dropdown:
        return ValidateDropdownField(answer, elementConfig, t)
      case ElementTypeEnum.Checkbox:
        return ValidateCheckboxField(answer, elementConfig, t)
      case ElementTypeEnum.Date:
        return ValidateDateField(answer, elementConfig, t)
      case ElementTypeEnum.File:
        return ValidateFileField(answer, elementConfig, t)
      case ElementTypeEnum.Header:
      case ElementTypeEnum.PageBreak:
        return []
    }
  }

  const validateElement = (elementConfig: ElementConfig, answer: Answer | null): boolean => {
    const errors = getErrors(elementConfig, answer)

    setStepErrors(stepErrors => [...stepErrors.filter(item => item.elementId !== elementConfig.id), ...errors.map(item => ({ ...item, elementId: elementConfig.id }))])
    return errors.length === 0
  }

  //Returns answer object for form element 
  const getAnswer = (elementId: string): Answer | null => {
    const answerData = answers.find(item => item.elementId === elementId)
    return answerData ? answerData : null
  }


  //Updates form element value
  const updateValue = (element: ElementConfig, value: any, clearFields: ErrorFieldType[] | null) => {
    const exists = answers.findIndex(item => item.elementId === element.id)
    if (exists !== -1) {
      const newAnswers = answers.map(item => {
        if (item.elementId === element.id) {
          return { ...item, value: value }
        }
        return item
      })
      setAnswers(newAnswers)
    } else {
      setAnswers([...answers, { elementId: element.id, elementType: element.type, value: value }])
    }

    if (clearFields === null) { //clear all errors for this element
      setStepErrors(errors => errors.filter(item => item.elementId !== element.id))
    } else {
      setStepErrors(errors => errors.filter(item => item.elementId !== element.id || !clearFields.includes(item.field)))
    }
  }


  const isLastStep = splitData.length - 1 <= step
  const isFirstStep = step === 0

  //Moves form to next step
  const nextStep = () => {
    if (isLastStep) {
      return
    }

    if (validate()) {
      setStep(step + 1)
    }
  }

  //Returns form to previous step
  const previousStep = () => {
    if (!isFirstStep) {
      setStepErrors([])
      setStep(step - 1)
    }
  }

  //Returnes error for form element
  const getError = (elementId: string, field: ErrorFieldType): AnswerError | null => {
    const result = stepErrors.find(item => item.elementId === elementId && item.field === field)
    return result ? result : null
  }


  const renderQuestion = (element: ElementConfig, isFirst: boolean): JSX.Element | null => {
    const answerData = getAnswer(element.id)
    const updateFn = (value: any, clearFields: ErrorFieldType[] | null) => updateValue(element, value, clearFields)

    switch (element.type) {
      case ElementTypeEnum.Date:
        return <DateField key={element.id} element={element} error={getError(element.id, null)} answerData={answerData} updateValue={updateFn} />
      case ElementTypeEnum.Text:
        return <TextField key={element.id} element={element} error={getError(element.id, null)} answerData={answerData} updateValue={updateFn} />
      case ElementTypeEnum.Email:
        return <EmailField key={element.id} element={element} error={getError(element.id, null)} answerData={answerData} updateValue={updateFn} />
      case ElementTypeEnum.Dropdown:
        return <DropdownField key={element.id} element={element} error={getError(element.id, null)} answerData={answerData} updateValue={updateFn} />
      case ElementTypeEnum.Radio:
        return <RadioField key={element.id} element={element} error={getError(element.id, null)} answerData={answerData} updateValue={updateFn} />
      case ElementTypeEnum.Checkbox:
        return <CheckboxField key={element.id} element={element} error={getError(element.id, null)} answerData={answerData} updateValue={updateFn} />
      case ElementTypeEnum.Header:
        return <Box key={element.id} style={{ marginTop: isFirst ? 15 : 0, marginBottom: 10, fontSize: 20 }}>{element.name}</Box>
      case ElementTypeEnum.EmploymentHistory:
        return <EmploymentHistoryField validate={() => validateElement(element, answerData)} key={element.id} getError={(field: ErrorFieldType) => getError(element.id, field)} element={element} t={t} answerData={answerData} updateValue={updateFn} />
      case ElementTypeEnum.File:
        return <FileField key={element.id} element={element} error={getError(element.id, null)} answerData={answerData} updateValue={updateFn} />
      case ElementTypeEnum.PageBreak:
        return null //No need implementation for this type
    }
  }

  return <ContentWrapper>
    <Box style={{ marginBottom: 15, fontSize: 20 }}>
      <Section>
        {form.name}
      </Section>
    </Box>
    <Box style={{ marginBottom: 15 }}>
      <Section>
        <Box style={{ gap: 15, display: "flex", flexDirection: "column" }}>
          {splitData[step].map((element, i) => renderQuestion(element, i === 0))}
        </Box>
      </Section>
    </Box>
    <Box style={{ gap: 10, display: "flex" }}>
      {!isFirstStep && <Button variant="outlined" onClick={previousStep}>{t("PREVIOUS_STEP")}</Button>}
      {isLastStep ?
        <Button disabled={stepErrors.length > 0} variant="contained" onClick={() => submitForm()}>{t("SUBMIT")}</Button>
        :
        <Button disabled={stepErrors.length > 0} variant="contained" onClick={nextStep}>{t("NEXT_STEP")}</Button>}
    </Box>
  </ContentWrapper>
};

export default FormView;
