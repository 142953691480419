import { AuthService } from "lib/Auth/AuthService";
import { Client, UserData } from "lib/Auth/Models";
import AuthorizedAxiosInstance from "./AuthorizedAxiosInstance";
import ExtendedAxios from "./ExtendedAxios";


type Response<T> = {
    status: number,
    errorCode: string | null,
    errorMessage: string | null,
    data: T
}

export enum LoginType {
    UsernamePassword = "username_password",
    EmailPassword = "email_password"
}

export type LoginRequest = {
    email?: string,
    username?: string,
    password: string,
    login_type: LoginType
}

class AuthApiService implements AuthService {
    host: string = "/api/auth/v3";

    getCookieDomain(): string {
        const parts = window.location.hostname.split(".")
        if (parts.length === 3) {
            return `.${parts[1]}.${parts[2]}`
        }
        return window.location.hostname
    }

    loginUsername(username: string, password: string): Promise<boolean> {
        const request: LoginRequest = {
            username: username,
            password: password,
            login_type: LoginType.UsernamePassword
        }

        return ExtendedAxios.post(`${this.host}/auth/login?cookie_domain=${this.getCookieDomain()}`, request).then(() => true).catch(() => false)
    }

    loginEmail(email: string, password: string): Promise<boolean> {
        const request: LoginRequest = {
            email: email,
            password: password,
            login_type: LoginType.EmailPassword
        }

        return ExtendedAxios.post(`${this.host}/auth/login?cookie_domain=${this.getCookieDomain()}`, request).then(() => true).catch(() => false)
    }

    async login(emailUsername: string, password: string): Promise<boolean> {
        const resultUsername = await this.loginUsername(emailUsername, password)
        if (resultUsername) {
            return new Promise<boolean>(resolve => resolve(resultUsername))
        }
        return this.loginEmail(emailUsername, password)

    }

    refresh(): Promise<boolean> {
        return ExtendedAxios.post(`${this.host}/auth/refresh?cookie_domain=${this.getCookieDomain()}`).then(() => true).catch(e => false)
    }

    me(): Promise<UserData | null> {
        return AuthorizedAxiosInstance.get<Response<UserData | null>>(`${this.host}/auth/me`).then(value => value.data.data).catch(e => null)
    }

    logout(): Promise<boolean> {
        return AuthorizedAxiosInstance.post<Response<any>>(`${this.host}/auth/logout?cookie_domain=${this.getCookieDomain()}`).then(() => true).catch(e => false)
    }

    getClientsList(): Promise<Client[] | null> {
        const result = ExtendedAxios.get<Response<Client[] | null>>(`${this.host}/clients`).then(value => value.data.data)
        return result
    }
}

export default new AuthApiService()