import { AdaptIsoDates } from "./ExtendedAxios";
import AuthApiService from "./AuthApiService";
import axios from "axios";

const AuthorizedAxiosInstance = axios.create();

const missingJwtMessage = "missing_jwt"


let isRefreshing = false;
let subscribers: ((ok: boolean) => void)[] = [];

const onRefreshed = (ok: boolean) => {
    subscribers.map(cb => cb(ok));
}
function subscribeTokenRefresh(cb: (ok: boolean) => void) {
    subscribers.push(cb);
}

//transform iso dates
AuthorizedAxiosInstance.interceptors.response.use(AdaptIsoDates);

// Response interceptor for API calls
AuthorizedAxiosInstance.interceptors.response.use((response: any) => {
    return response
}, async function (error: any) {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.response.data?.message !== missingJwtMessage && !originalRequest._retry) {
        const result = new Promise((resolve, reject) => {
            subscribeTokenRefresh((ok: boolean): void => {
                if (!ok) {
                    reject(error)
                } else {
                    originalRequest._retry = true;
                    resolve(axios(originalRequest));
                }
            });
        });
        
        if (!isRefreshing) {
            isRefreshing = true
            AuthApiService.refresh().then((ok => {
                isRefreshing = false
                onRefreshed(ok)
                subscribers = []
            }))

        }

        //add to queue to execute after refresh finished
        return result;
    }
    return Promise.reject(error);
});

export default AuthorizedAxiosInstance;