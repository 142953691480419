const locale = {
    //Editing statuses
    STATUS_SAVED: "Saved",
    STATUS_SAVING: "Saving...",
    STATUS_EDITING: "Editing...",
    STATUS_INCOMPLETE_DATA: "Incomplete info or fields",

    FORM_TAB_INFO: "Info",
    FORM_TAB_ANSWERS: "Answers",
    FORM_TAB_STRUCTURE: "Form fields",

    //Form fields
    ELEMENT_NAME_FIELD: "Question",

    ELEMENT_TITLE_FIELD: "Title",
    ELEMENT_ROW_COUNT_FIELD: "Row number",
    ELEMENT_DESCRIPTION_FIELD: "Description",
    ELEMENT_REQUIRED_FIELD: "Required?",
    ELEMENT_SHOW_IN_TABLE_FIELD: "Show in answers table",
    FORM_NAME_FIELD: "Title",
    FORM_DESCRIPTION_FIELD: "Description",
    FORM_SUCCESS_TEXT_FIELD: "Success message",
    ANSWER_VALUE_PLACEHOLDER: "Enter value",
    ANSWER_NEW_VALUE_PLACEHOLDER: "Add new vlaue",
    ANSWER_VALUES_TITLE: "Values",

    //ELEMENT type names
    ELEMENT_TYPE_TEXT: "Text",
    ELEMENT_TYPE_DROPDOWN: "Dropdown",
    ELEMENT_TYPE_RADIO: "Selection",
    ELEMENT_TYPE_CHECKBOX: "Checkbox",
    ELEMENT_TYPE_FILE: "File",
    ELEMENT_TYPE_DATE: "Date",
    ELEMENT_TYPE_EMAIL: "Email",
    ELEMENT_TYPE_HEADER: "Title",
    ELEMENT_TYPE_PAGE_BREAK: "Page break",
    ELEMENT_TYPE_EMPLOYMENT_HISTORY: "Employment history",

    //Form exit
    FORM_EDITED_EXIT_CONFIRM_TEXT: "There are unsaved changes. Are you sure?",
    FORM_EDITED_EXIT_CONFIRM_YES: "Yes",
    FORM_EDITED_EXIT_CONFIRM_NO: "No",

    //Camera
    CAMERA_FRONT: "Front",
    CAMERA_BACK: "Back",
    CAMERA: "Camera for photo",

    //Delete element
    DELETE_CONFIRM_MESSAGE: "Are you sure?",
    DELETE_CONFIRM_YES: "Yes",
    DELETE_CONFIRM_NO: "No",
};


export default locale