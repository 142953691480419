import dayjs, { Dayjs } from "dayjs"
import { FC } from "react"
import { AnswerError, ErrorFieldType, PartialAnswerError } from "../FormView"
import SimpleQuestionFieldWrapper from "./SimpleQuestionFieldWrapper"
import { Answer, ElementConfig } from "lib/Form/Models"
import DatePicker from "components/DatePicker";


type Props = {
    element: ElementConfig,
    error: AnswerError | null,
    answerData: Answer | null,
    updateValue: (value: any, clearFields: ErrorFieldType[] | null) => void
}

export const ValidateDateField = (answerData: Answer | null, elementConfig: ElementConfig, t: any): PartialAnswerError[] => {

    const value: string = answerData && answerData.value ? answerData.value : ""

    if (elementConfig.required) {
        if (!value) {
            return [{
                field: null,
                error: t("REQUIRED_FIELD_LEFT_EMPTY_ERROR")
            }]
        }
    }

    return []
}

const DateField: FC<Props> = ({ element, error, answerData, updateValue }) => {
    return <SimpleQuestionFieldWrapper element={element} error={error}>
        <DatePicker value={answerData ? dayjs(answerData.value) : null} onChange={(item: Dayjs | null) => updateValue(item?.toISOString(), null)} clearable={!element.required} />
    </SimpleQuestionFieldWrapper>
}

export default DateField