const locale = {
    EMPLOYER_FORM_EMPLOYER_FIELD: "Darbovietės pavadinimas",
    EMPLOYER_FORM_ADDRESS_FIELD: "Adresas",
    EMPLOYER_FORM_PHONE_FIELD: "Telefono numeris",
    EMPLOYER_FORM_SUPERVISOR_FIELD: "Vadybininkas",
    EMPLOYER_FORM_POSITION_FIELD: "Pareigos",
    EMPLOYER_FORM_FROM_FIELD: "Nuo",
    EMPLOYER_FORM_TO_FIELD: "Iki",

    DOWNLOAD_FILE: "Parsisiųsti failą",

    //Statuses
    ANSWER_STATUS_NEW: "Naujas",
    ANSWER_STATUS_VIEWED: "Peržiūrėta",
    ANSWER_STATUS_CONFIRMED: "Patvirtinta",
    ANSWER_STATUS_CANCELLED: "Atšaukta",

    DELETE_CONFIRM_TEXT: "Ar tikrai trinti?",

    STATUS_CHANGE_LABEL: "Būklė"
};


export default locale