const locale = {
    TABLE_COLUMN_DATE: "Data",
    TABLE_COLUMN_STATUS: "Statusas",
    TABLE_COLUMN_ACTIONS: "Veiksmai",

    //Statuses
    ANSWER_STATUS_NEW: "Naujas",
    ANSWER_STATUS_VIEWED: "Peržiūrėta",
    ANSWER_STATUS_CONFIRMED: "Patvirtinta",
    ANSWER_STATUS_CANCELLED: "Atšaukta",

    //Buttons
    ACTION_CHANGE_STATUS: "Keisti statusą",
    ACTION_DELETE: "Trinti",

    //Status Change Dialog
    CHANGE_STATUS_LABEL: "Pasirinkite statusą",
    CHANGE_STATUS_SUBMIT: "Keisti",

    DELETE_CONFIRM_TEXT: "Ar tikrai trinti?",
};


export default locale