import { FC, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LoginIcon from '@mui/icons-material/Login';
import TextField from '@mui/material/TextField';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Button from '@mui/lab/LoadingButton';

export type LoginFormValues = {
  login: string;
  password: string;
};

type Props = {
  logo?: ReactNode;
  loading?: boolean;
  onSubmit: (values: LoginFormValues) => void;
};

const Login: FC<Props> = ({ logo, loading = false, onSubmit }) => {
  const { t } = useTranslation('login');


  const [loginForm, setLoginForm] = useState<LoginFormValues>({ login: "", password: "" })


  const fieldChanged = (field: string, value: string) => {
    setLoginForm({ ...loginForm, [field]: value })
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {logo}

        <Box sx={{ mt: 1 }}>
          <TextField
            onChange={e => fieldChanged(e.target.name, e.target.value)}
            value={loginForm.login}
            margin="normal"
            fullWidth
            label={t('FIELD_EMAIL_USERNAME')}
            name="login"
            variant="standard"
            autoFocus
          />

          <TextField
            onChange={e => fieldChanged(e.target.name, e.target.value)}
            margin="normal"
            fullWidth
            name="password"
            label={t('FIELD_PASSWORD')}
            variant="standard"
            type="password"
            autoComplete="current-password"
          />

          <Button
            onClick={() => onSubmit(loginForm)}
            fullWidth
            variant="contained"
            loading={loading}
            sx={{ mt: 3, mb: 2 }}
            startIcon={<LoginIcon />}>
            {t('BUTTON_TEXT_LOGIN')}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
