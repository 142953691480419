export type UserData = {
    id: string
    firstName: string
    lastName: string
    email: string | null
    roles: string[]
    username: string | null
    clients: Client[]
    clientIdList: string[]
}

export type Client = {
    id: string
    name: string
    domains: string[]
    appEnabled: boolean
}

enum UserRoles {
    Super = "super",
    Admin = "admin"
}


export const IsSuper = (user: UserData) => !!user.roles?.includes(UserRoles.Super)