const locale = {
    //Table
    FORMS_TABLE_TITLE:"Formų sąrašas",
    TABLE_COLUMN_NAME: "Pavadinimas",
    TABLE_COLUMN_ACTIONS: "Veiksmai",
    NO_FORMS_IN_LIST: "Formų sąrašas tuščias",
    //Table actions
    TABLE_ACTION_PREVIEW: "Formos pildymas",
    TABLE_ACTION_ANSWERS: "Atsakymai",
    TABLE_ACTION_EDIT: "Redaguoti",
    TABLE_ACTION_DELETE: "Trinti",

    DELETE_CONFIRM_MESSAGE: "Ar tikrai trinti?",
    DELETE_CONFIRM_YES: "Taip",
    DELETE_CONFIRM_NO: "Ne",
};


export default locale