import { Box } from "@mui/material"
import { FC } from "react"


const ConentWrapper: FC<{ width?: number, children?: React.ReactNode }> = ({ width, children }) => {
    return <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ maxWidth: width ? width : 750, width: "100%" }}>
            {children}
        </Box>
    </Box>
}

export default ConentWrapper