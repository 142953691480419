import { FC } from "react"
import { AnswerError } from "../FormView"
import { FormControl, FormLabel, FormHelperText } from '@mui/material';
import { ElementConfig } from "lib/Form/Models";



const SimpleQuestionFieldWrapper: FC<{ children?: JSX.Element | JSX.Element[], element: ElementConfig, error: AnswerError | null}> = ({ children, element, error }) => {
    return <FormControl error={!!error} fullWidth>
        <FormLabel>{element.name} {element.required ? "*" : ""}</FormLabel>
        <div>{element.description}</div>
        {children}
        <FormHelperText>{error ? error.error : ""}</FormHelperText>
    </FormControl>
}

export default SimpleQuestionFieldWrapper