import { FC } from 'react';

import { MobileDatePicker } from '@mui/x-date-pickers';
import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { Dayjs } from 'dayjs';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';


type Props = {
    value: Dayjs | null,
    onChange: (item: Dayjs | null) => void
    clearable?: boolean
}

const DatePicker: FC<Props> = (props) => {
    return <MobileDatePicker {...props} closeOnSelect={true} showToolbar={false} views={['year', 'month', 'day']} openTo="year" renderInput={(params: TextFieldProps) => <TextField {...params} InputProps={{
        startAdornment: (
            <InputAdornment position="start">
                <DateRangeIcon />
            </InputAdornment>
        ),
        endAdornment: props.clearable && (
            <IconButton onClick={(e) => {
                e.stopPropagation()
                props.onChange(null)
            }}>
                <ClearIcon />
            </IconButton>
        )
    }} />} />
};

export default DatePicker;
