import { FC, useCallback, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { useTranslation } from 'react-i18next';
import { Form } from 'lib/Form/Models';
import { useNavigate } from 'react-router';
import { Fab } from '@brunas/dashboard';
import useFormService from 'services/useFormService';
import { LinearProgress } from '@mui/material';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import { Column, Datatable, useDatatable } from "@brunas/react-mui-datatable"
import { Client } from 'lib/Auth/Models';
import { useConfirm } from 'components/ConfirmDialog';


const FormDatatable: FC<{ client: Client }> = ({ client }) => {
    const navigate = useNavigate()
    const service = useFormService()
    const confirm = useConfirm()

    const [deleteingIdList, setDeletingIdList] = useState<string[]>([])

    const { setData, ...datatableData } = useDatatable<Form, Client>({ service: service, config: client })

    const { t } = useTranslation("form-list")

    const createNew = useCallback(() => {
        navigate("/form-edit")
    }, [navigate])


    const editForm = useCallback((form: Form) => {
        navigate("/form-edit/" + form.id)
    }, [navigate])


    const deleteForm = useCallback(async (id: string) => {
        if (await confirm({ message: t("DELETE_CONFIRM_MESSAGE") })) {
            setDeletingIdList(list => [...list, id])
            setData(data => data.filter(item => item.id !== id))
            try {
                await service?.deleteForm(id)
            } catch (e) {
                //TODO error
            }
            setDeletingIdList(list => list.filter(item => item !== id))
        }
    }, [service, setData, t, confirm])


    const columns = useMemo<Column<Form>[]>(() => [
        {
            sortingField: 'name',
            header: t("TABLE_COLUMN_NAME"),
            value: (row: Form) => row.name
        },
        {
            header: t("TABLE_COLUMN_ACTIONS"),
            getActions: (params: GridRowParams) => {
                const actions = [];
                if (!deleteingIdList.includes(params.row.id)) {
                    actions.push(<GridActionsCellItem icon={<DeleteIcon />} onClick={() => deleteForm(params.row.id)} label={t("TABLE_ACTION_DELETE")} />)
                }
                return actions
            },
        }
    ], [deleteingIdList, t, deleteForm])


    if (!client) {
        return <LinearProgress />
    }

    return <>
        <Fab icon={<AddIcon />} onClick={createNew} />

        <Datatable {...datatableData} setData={setData} key={"form-datatable"} title={t("FORMS_TABLE_TITLE")} globalSearchEnabled={true} onRowClick={editForm} columns={columns}></Datatable>
    </>
};

export default FormDatatable;
