import { TextField as MuiTextField } from "@mui/material"
import { FC } from "react"
import { AnswerError, ErrorFieldType, PartialAnswerError } from "../FormView"
import SimpleQuestionFieldWrapper from "./SimpleQuestionFieldWrapper"
import { Answer, ElementConfig } from "lib/Form/Models"


type Props = {
    element: ElementConfig,
    error: AnswerError | null,
    answerData: Answer | null,
    updateValue: (value: any, clearFields: ErrorFieldType[] | null) => void
}

export const ValidateTextField = (answerData: Answer | null, elementConfig: ElementConfig, t: any): PartialAnswerError[] => {

    const value: string = answerData && answerData.value ? answerData.value : ""

    if (elementConfig.required) {
        if (!value) {
            return [{
                field: null,
                error: t("REQUIRED_FIELD_LEFT_EMPTY_ERROR")
            }]
        }
    }

    return []
}

const TextField: FC<Props> = ({ element, error, answerData, updateValue }) => {

    const answerConfig = element.answerConfig

    return <SimpleQuestionFieldWrapper element={element} error={error}><MuiTextField multiline={!!(answerConfig?.rowCount && answerConfig.rowCount > 1)} rows={answerConfig?.rowCount} value={answerData ? answerData.value : ""} onChange={(e) => updateValue(e.target.value, null)} /></SimpleQuestionFieldWrapper>
}

export default TextField