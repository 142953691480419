const locale = {
    //Table
    NEXT_STEP: "Next",
    PREVIOUS_STEP: "Back",

    REQUIRED_FIELD_LEFT_EMPTY_ERROR: "Field is required",
    INVALID_EMAIL_FIELD_VALUE: "Invalid email address",


    EMPLOYER_LIST_MANDATORY: "At least one employer is required",
    FROM_DATE_CANNOT_BE_LATER_THAN_TO_DATE: "End date must be after start date",

    EMPLOYER_FORM_EMPLOYER_FIELD: "Company name",
    EMPLOYER_FORM_ADDRESS_FIELD: "Address",
    EMPLOYER_FORM_PHONE_FIELD: "Phone",
    EMPLOYER_FORM_SUPERVISOR_FIELD: "Supervisor",
    EMPLOYER_FORM_POSITION_FIELD: "Position",
    EMPLOYER_FORM_FROM_FIELD: "From",
    EMPLOYER_FORM_TO_FIELD: "To",

    ADD_EMPLOYER: "Add employer",

    FORM_DEFAULT_SUCCESS_MESSAGE: "Form submitted",

    FILE_SELECT_FROM_STORAGE: "Upload",
    FILE_TAKE_PHOTO: "Take photo",
    CAMERA_ERROR: "Unable to access camera. Check permissions.",
    FILE_FIELD_ATTACHED_FILE:"Attached file",

    SUBMIT_CONFIRM_MESSAGE: "Are you sure?",
    SUBMIT_CONFIRM_YES: "Yes",
    SUBMIT_CONFIRM_NO: "No",
};


export default locale