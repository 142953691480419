import { FC, useCallback, useMemo, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';


import { useTranslation } from 'react-i18next';
import { AnswerTableItem, ElementConfig, ElementTypeEnum, Form, FormAnswerStatusEnum } from 'lib/Form/Models';
import { useNavigate } from 'react-router';
import { isUrlImage } from 'lib/Utils/is-image';
import useFormAnswersService from 'services/useFormAnswersService';
import { Datatable, SelectFilter, useDatatable, DatatableFilterProps, FilterValueUpdateProps, SelectFilterValue, SelectFilterValueType, ActionButton, SortingDirectionEnum, Column } from '@brunas/react-mui-datatable';
import { GridSelectionModel } from '@mui/x-data-grid';
import Thumbnail from 'components/Thumbnail';
import { Button, Chip, Dialog, DialogContent, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import { Box } from '@mui/system';
import { useConfirm } from 'components/ConfirmDialog';



const FormAnswerList: FC<{ formConfig: Form }> = ({ formConfig }) => {
    const formAnswersService = useFormAnswersService()
    const navigate = useNavigate()
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isChangingStatus, setIsChangingStatus] = useState<boolean>(false)
    const { refreshData, dataUpdating, ...datatableData } = useDatatable<AnswerTableItem, Form>({
        service: formAnswersService, config: formConfig, defaultSorting: [
            {
                field: "timestamp",
                direction: SortingDirectionEnum.Descending
            }
        ]
    })

    const confirm = useConfirm()

    const [showStatusChangeDialog, setShowStatusChangeDialog] = useState<boolean>(false)
    const [statusChangeValue, setStatusChangeValue] = useState<FormAnswerStatusEnum>(FormAnswerStatusEnum.New)
    const [statusChangeSelection, setStatusChangeSelection] = useState<GridSelectionModel>([])

    const { t } = useTranslation('form-answer-list')

    const previewAnswer = (answer: AnswerTableItem) => {
        navigate(`/forms/${formConfig.id}/answers/${answer.id}`)
    }


    const deleteAnswer = async (answerId: string) => {
        await formAnswersService.deleteAnswer(formConfig.id, answerId)
    }
    const changeStatus = async (answerId: string, status: FormAnswerStatusEnum) => {
        await formAnswersService.changeStatus(formConfig.id, answerId, status)
    }

    const tableElements = formConfig.elements.filter(element => element.showInTable)

    const formatAnswerStatus = useCallback((answerStatus: FormAnswerStatusEnum): JSX.Element => {
        switch (answerStatus) {
            case FormAnswerStatusEnum.New:
                return <Chip color={"default"} label={t("ANSWER_STATUS_NEW")} />
            case FormAnswerStatusEnum.Viewed:
                return <Chip color={"info"} label={t("ANSWER_STATUS_VIEWED")} />
            case FormAnswerStatusEnum.Confirmed:
                return <Chip color={"success"} label={t("ANSWER_STATUS_CONFIRMED")} />
            case FormAnswerStatusEnum.Cancelled:
                return <Chip color={"error"} label={t("ANSWER_STATUS_CANCELLED")} />
        }
    }, [t])


    const formatCellValue = useCallback((config: ElementConfig, answer: any): JSX.Element => {
        if (!answer) {
            return <></>
        }
        if (config.type === ElementTypeEnum.File) {
            if (isUrlImage(answer)) {
                return <Thumbnail url={answer} />
            }
            return <IconButton LinkComponent={"a"} download href={answer}><InsertDriveFileIcon /></IconButton>
        }
        return <span>{Array.isArray(answer) ? answer.join(", ") : answer}</span>
    }, [])


    const columns = useMemo<Column<AnswerTableItem>[]>(() => [
        {
            value: (answer: AnswerTableItem) => answer.timestamp.format("YYYY-MM-DD HH:mm"),
            sortingField: "timestamp",
            header: t("TABLE_COLUMN_DATE"),
        },

        ...tableElements.map(element => ({
            value: (answer: AnswerTableItem) => formatCellValue(element, answer.staticFields[element.id]),
            sortingField: "static_fields." + element.id,
            header: element.name,
        })),

        {
            value: (answer: AnswerTableItem) => formatAnswerStatus(answer.status),
            sortingField: 'status',
            header: t("TABLE_COLUMN_STATUS"),
        }
    ], [t, formatCellValue, tableElements, formatAnswerStatus])



    const statusValues: SelectFilterValue[] = [
        {
            value: FormAnswerStatusEnum.New,
            label: t("ANSWER_STATUS_NEW")
        },
        {
            value: FormAnswerStatusEnum.Viewed,
            label: t("ANSWER_STATUS_VIEWED")
        },
        {
            value: FormAnswerStatusEnum.Confirmed,
            label: t("ANSWER_STATUS_CONFIRMED")
        },
        {
            value: FormAnswerStatusEnum.Cancelled,
            label: t("ANSWER_STATUS_CANCELLED")
        }
    ]

    const filters = (props: FilterValueUpdateProps): React.ReactElement<DatatableFilterProps>[] => [
        <SelectFilter key="status-field" field="status" label={t("TABLE_COLUMN_STATUS")} filterValueType={SelectFilterValueType.String} values={statusValues} {...props} />
    ]


    const getGlobalPlaceholderText = (): string => {
        const items = tableElements.slice(0, 3).map(element => element.name)

        return items.join(", ")
    }

    const changeMultipleStatuses = async () => {
        setShowStatusChangeDialog(false)
        setIsChangingStatus(true)
        await Promise.all(statusChangeSelection.map(async x => await changeStatus(x.toString(), statusChangeValue)))
        setIsChangingStatus(false)
        refreshData()
    }

    const buttons: ActionButton[] = [
        {
            label: t("ACTION_CHANGE_STATUS"),
            onClick: async (selection: GridSelectionModel) => {
                if (selection.length === 0) {
                    return
                }
                setStatusChangeSelection(selection)
                setShowStatusChangeDialog(true)
            },
            refreshNeeded: false
        },
        {
            label: t("ACTION_DELETE"),
            onClick: async (selection: GridSelectionModel) => {
                if (selection.length === 0) {
                    return
                }
                if (await confirm({ message: t("DELETE_CONFIRM_TEXT") })) {
                    setIsDeleting(true)
                    await Promise.all(selection.map(async x => await deleteAnswer(x.toString())))
                    setIsDeleting(false)
                }
            },
            refreshNeeded: true
        }
    ]

    return <>
        <Dialog open={showStatusChangeDialog} onClose={() => setShowStatusChangeDialog(false)}>
            <DialogContent sx={{ minWidth: 250 }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                    <FormControl>
                        <FormLabel>{t("CHANGE_STATUS_LABEL")}</FormLabel>
                        <RadioGroup value={statusChangeValue}>
                            {Object.values(FormAnswerStatusEnum).map(status => {
                                return <FormControlLabel key={status} value={status} control={<Radio onChange={e => setStatusChangeValue(e.target.value as FormAnswerStatusEnum)} />} label={formatAnswerStatus(status)} />
                            })}
                        </RadioGroup>
                    </FormControl>
                    <Button variant="contained" onClick={() => changeMultipleStatuses()}>{t("CHANGE_STATUS_SUBMIT")}</Button>
                </Box>
            </DialogContent>
        </Dialog>
        <Datatable refreshData={refreshData} dataUpdating={isDeleting || isChangingStatus || dataUpdating} buttons={buttons} filters={filters} {...datatableData} key={"form-answers-datatable"} globalSearchPlaceholder={getGlobalPlaceholderText()} globalSearchEnabled={tableElements.length > 0} onRowClick={previewAnswer} columns={columns}></Datatable>
    </>
};

export default FormAnswerList;
