const locale = {
    TABLE_COLUMN_DATE: "Date",
    TABLE_COLUMN_STATUS: "Status",
    TABLE_COLUMN_ACTIONS: "Actions",

    //Statuses
    ANSWER_STATUS_NEW: "New",
    ANSWER_STATUS_VIEWED: "Viewed",
    ANSWER_STATUS_CONFIRMED: "Confirmed",
    ANSWER_STATUS_CANCELLED: "Cancelled",

    //Buttons
    ACTION_CHANGE_STATUS: "Change status",
    ACTION_DELETE: "Delete",

    //Status Change Dialog
    CHANGE_STATUS_LABEL: "Select status",
    CHANGE_STATUS_SUBMIT: "Change",

    DELETE_CONFIRM_TEXT:"Are you sure?",
};


export default locale