import { FC } from "react"
import { AnswerError, ErrorFieldType, PartialAnswerError } from "../FormView"
import SimpleQuestionFieldWrapper from "./SimpleQuestionFieldWrapper"
import { Answer, ElementConfig } from "lib/Form/Models"
import { FormGroup, Checkbox, FormControlLabel } from '@mui/material';


type Props = {
  element: ElementConfig,
  error: AnswerError | null,
  answerData: Answer | null,
  updateValue: (value: any, clearFields: ErrorFieldType[] | null) => void
}

export const ValidateCheckboxField = (answerData: Answer | null, elementConfig: ElementConfig, t: any): PartialAnswerError[] => {

  const value: string[] = answerData && Array.isArray(answerData.value) ? answerData.value : []

  if (elementConfig.required) {
    if (value.length === 0) {
      return [{
        field: null,
        error: t("REQUIRED_FIELD_LEFT_EMPTY_ERROR")
      }]
    }
  }

  return []
}

const CheckboxField: FC<Props> = ({ element, error, answerData, updateValue }) => {
  const answerConfig = element.answerConfig
  const selectedValues: string[] = answerData ? answerData.value : []

  return <SimpleQuestionFieldWrapper element={element} error={error}>
    <FormGroup>
      {answerConfig && answerConfig.values && answerConfig.values.map((value, i) => <FormControlLabel key={i}
        control={
          <Checkbox name={value.value} value={value.id ? value.id : ""} checked={selectedValues.includes(value.id ? value.id : "")} onChange={e => {
            const newValues = selectedValues.filter(oldValue => oldValue !== value.id ? value.id : "")
            if (e.target.checked) {
              newValues.push(value.id ? value.id : "")
            }
            updateValue(newValues, null)
          }} />
        }
        label={value.value}
      />)}
    </FormGroup>
  </SimpleQuestionFieldWrapper>
}

export default CheckboxField