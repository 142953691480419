import { Dayjs } from "dayjs"

export type Form = {
    id: string
    name: string
    description: string | null,
    successText: string | null,
    elements: ElementConfig[]
}

export type AnswerTableItem = {
    id: string
    timestamp: Dayjs
    status: FormAnswerStatusEnum
    staticFields: any
}
export type FormAnswer = {
    id: string
    timestamp: string
    status: FormAnswerStatusEnum
    schema: Form
    answers: Answer[]
}

export enum FormAnswerStatusEnum {
    New = "new",
    Viewed = "viewed",
    Confirmed = "confirmed",
    Cancelled = "cancelled",
}

export enum ElementTypeEnum {
    Text = "text",
    Dropdown = "dropdown",
    Radio = "radio",
    Checkbox = "checkbox",
    File = "file",
    Date = "date",
    Email = "email",
    Header = "header",
    PageBreak = "pageBreak",
    EmploymentHistory = "employmentHistory",
}

export type ElementConfig = {
    id: string,
    type: ElementTypeEnum,
    name: string,
    description: string,
    required: boolean,
    showInTable: boolean,
    answerConfig: AnswerConfig | null
}

export enum WebCam {
    Front = "front",
    Back = "back"
}

export type AnswerConfig = {
    values: AnswerValue[],
    rowCount: number,
    camera: WebCam | null
}



export const AvailableTypes: ElementTypeEnum[] = Object.values(ElementTypeEnum)
export const TypesForAnswersTable: ElementTypeEnum[] = [
    ElementTypeEnum.Text,
    ElementTypeEnum.Dropdown,
    ElementTypeEnum.Radio,
    ElementTypeEnum.Checkbox,
    ElementTypeEnum.File,
    ElementTypeEnum.Email,
    ElementTypeEnum.Date,
]
export const TypesWithValues: ElementTypeEnum[] = [ElementTypeEnum.Dropdown, ElementTypeEnum.Radio, ElementTypeEnum.Checkbox]
export const EmptyTypes: ElementTypeEnum[] = [ElementTypeEnum.PageBreak]

export type AnswerValue = {
    id: string | null
    value: string
}
export type Answer = {
    elementId: string,
    elementType: ElementTypeEnum,
    value: any
}


export type EmployerAnswerItem = {
    employer: string,
    from: string,
    to: string | null,
    address: string,
    phone: string,
    supervisor: string,
    position: string
}

export const isFormValid = (form: Form): boolean => {
    if (form.name === "") {
        return false
    }
    if (form.elements.length === 0) {
        return false
    }
    if (form.elements.filter(element => !isElementConfigValid(element)).length > 0) {
        return false
    }
    return true
}

export const isElementConfigValid = (element: ElementConfig) => {
    if (EmptyTypes.includes(element.type)) {
        return true
    }
    if (element.name === "") {
        return false
    }

    if (TypesWithValues.includes(element.type)) {
        if (!element.answerConfig) {
            return false
        }
        if (element.answerConfig.values.filter(value => value.value !== "").length === 0) {
            return false
        }
    }

    return true
}