const locale = {
    EMPLOYER_FORM_EMPLOYER_FIELD: "Company name",
    EMPLOYER_FORM_ADDRESS_FIELD: "Address",
    EMPLOYER_FORM_PHONE_FIELD: "Phone",
    EMPLOYER_FORM_SUPERVISOR_FIELD: "Supervisor",
    EMPLOYER_FORM_POSITION_FIELD: "Position",
    EMPLOYER_FORM_FROM_FIELD: "From",
    EMPLOYER_FORM_TO_FIELD: "To",

    DOWNLOAD_FILE: "Download file",

    //Statuses
    ANSWER_STATUS_NEW: "New",
    ANSWER_STATUS_VIEWED: "Viewed",
    ANSWER_STATUS_CONFIRMED: "Confirmed",
    ANSWER_STATUS_CANCELLED: "Cancelled",

    DELETE_CONFIRM_TEXT: "Are you sure?",

    STATUS_CHANGE_LABEL: "Status"
};


export default locale