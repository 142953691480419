import { FC } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import FormList from 'routes/FormList';
import FormEdit from 'routes/FormEdit';
import FormView from 'routes/FormView';
import UnauthorizedLayout from 'layouts/UnauthorizedLayout';
import AuthorizedLayout from 'layouts/AuthorizedLayout';
import Theme from '@brunas/dashboard/dist/Theme';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/en-CA'; //TODO switch locale by language (currently Canada)

import FormAnswerView from 'routes/FormAnswerView';
import UserDataProvider from 'lib/Auth/UserDataProvider';
import { ConfirmDialogProvider } from 'components/ConfirmDialog';


const App: FC = () => {
  return <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
    <Theme>
        <ConfirmDialogProvider>
          <Router>
            <Routes>
              <Route path="/form-submit" element={<UnauthorizedLayout />}>
                <Route path={":id"} element={<FormView />} />
              </Route>
              <Route path="/" element={<UserDataProvider><AuthorizedLayout /></UserDataProvider>}>
                <Route index={true} element={<FormList />} />
                <Route path={"form-edit"} element={<FormEdit />} />
                <Route path={"form-edit/:id"} element={<FormEdit />} />
                <Route path={"forms/:formId/answers/:answerId"} element={<FormAnswerView />} />
                <Route path={"*"} element={<div>Not found</div>} />
              </Route>
            </Routes>
          </Router >
        </ConfirmDialogProvider>
    </Theme>
  </LocalizationProvider>
};

export default App;
