
import { FC } from 'react';
import { FormControl, TextField, Checkbox, FormControlLabel, RadioGroup, Radio, FormLabel } from '@mui/material';
import { Box } from '@mui/system';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton'
import { ModifyFn } from './FormEdit';
import { ElementConfig, ElementTypeEnum, AnswerValue, TypesWithValues, EmptyTypes, TypesForAnswersTable, WebCam } from 'lib/Form/Models';

const ElementConfigEdit: FC<{ currentConfig: ElementConfig, modifyCurrent: ModifyFn, t: any }> = ({ currentConfig, modifyCurrent, t }) => {

    if (EmptyTypes.includes(currentConfig.type)) {
        return <></>
    }

    const currentAnswerValues: AnswerValue[] = currentConfig.answerConfig ? currentConfig.answerConfig.values : []

    const needsEmpty = TypesWithValues.includes(currentConfig.type) && (currentAnswerValues.length === 0 || currentAnswerValues[currentAnswerValues.length - 1].value !== "")

    if (needsEmpty) {
        currentAnswerValues.push({ value: "", id: null })
    }

    const updateElement = (field: string, value: any) => {
        modifyCurrent(q => ({
            ...q,
            [field]: value
        }))
    }

    const updateAnswer = (field: string, value: any) => {
        modifyCurrent(q => ({
            ...q,
            answerConfig: q.answerConfig ? {
                ...q.answerConfig,
                [field]: value
            } : null
        }))
    }

    const editAnswer = (index: number, value: string) => {
        modifyCurrent(q => ({
            ...q,
            answerConfig: q.answerConfig ? { ...q.answerConfig, values: q.answerConfig.values.map((item, i) => i !== index ? item : { ...item, value: value }) } : null
        }))
    }

    const deleteAnswer = (index: number) => {
        modifyCurrent(q => ({
            ...q,
            answerConfig: q.answerConfig ? { ...q.answerConfig, values: q.answerConfig.values.filter((item, i) => i !== index) } : null
        }))
    }

    const isHeader = currentConfig.type === ElementTypeEnum.Header

    const getWebCamName = (camera: WebCam): string => {
        switch (camera) {
            case WebCam.Front:
                return t("CAMERA_FRONT")
            case WebCam.Back:
                return t("CAMERA_BACK")

        }
    }

    return <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <FormControl fullWidth>
            <TextField required autoFocus error={currentConfig.name === ""} variant="standard" label={isHeader ? t("ELEMENT_TITLE_FIELD") : t("ELEMENT_NAME_FIELD")} name="name" onChange={(e) => updateElement(e.target.name, e.target.value)} value={currentConfig.name} />
        </FormControl>
        {false && <FormControl fullWidth>
            <TextField variant="standard" multiline={true} label={t("ELEMENT_DESCRIPTION_FIELD")} maxRows={5} name="description" onChange={(e) => updateElement(e.target.name, e.target.value)} value={currentConfig.description} />
        </FormControl>}
        {!isHeader && <FormControl>
            <FormControlLabel control={<Checkbox name="required" onChange={(e) => updateElement(e.target.name, e.target.checked)} checked={currentConfig.required} />} label={t("ELEMENT_REQUIRED_FIELD")} />
        </FormControl>}
        {TypesForAnswersTable.includes(currentConfig.type) && <FormControl>
            <FormControlLabel control={<Checkbox name="showInTable" onChange={(e) => updateElement(e.target.name, e.target.checked)} checked={currentConfig.showInTable} />} label={t("ELEMENT_SHOW_IN_TABLE_FIELD")} />
        </FormControl>}
        {currentConfig.type === ElementTypeEnum.Text && <FormControl fullWidth>
            <TextField variant="standard" type="number" name="rowCount" onChange={(e) => updateAnswer(e.target.name, parseInt(e.target.value))} value={currentConfig.answerConfig?.rowCount} label={t("ELEMENT_ROW_COUNT_FIELD")} />
        </FormControl>}
        {currentConfig.type === ElementTypeEnum.File && <FormControl fullWidth>
            <FormLabel>{t("CAMERA")}</FormLabel>
            <RadioGroup value={currentConfig.answerConfig?.camera ? currentConfig.answerConfig?.camera : ""} name="camera" onChange={(e) => updateAnswer(e.target.name, e.target.value)}>
                {Object.values(WebCam).map(value => <FormControlLabel key={value} value={value} control={<Radio />} label={getWebCamName(value)} />)}
            </RadioGroup>
        </FormControl>}
        {TypesWithValues.includes(currentConfig.type) && <>
            <div>{t("ANSWER_VALUES_TITLE")}</div>
            {currentAnswerValues.map((value, i) => {
                const isNew = (value.value === "" && i === currentAnswerValues.length - 1)
                const error = (!isNew || currentAnswerValues.length === 1) && value.value === ""
                return <ListItem key={i}>
                    <TextField fullWidth variant="standard" onChange={(e) => editAnswer(i, e.target.value)} required error={error} value={value.value} placeholder={value.id ? t("ANSWER_VALUE_PLACEHOLDER") : t("ANSWER_NEW_VALUE_PLACEHOLDER")} />

                    {(value.value !== "" || i !== currentAnswerValues.length - 1) && <IconButton tabIndex={-1} onClick={() => deleteAnswer(i)}>
                        <DeleteIcon />
                    </IconButton>}
                </ListItem>
            })}
        </>}
    </Box>
}
export default ElementConfigEdit;