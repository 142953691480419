import { AuthService } from "./AuthService";
import { UserData, Client } from "./Models";


const dummyClients: Client[] = [
    {
        id: "client1",
        name: "Client 1",
        domains: [
            "client1.test.lt"
        ],
        appEnabled: true
    },
    {
        id: "client2",
        name: "Client 2",
        domains: [
            "client2.test.lt"
        ],
        appEnabled: false
    }
]

const dummyUserData: UserData = {
    id: "test",
    firstName: "Test",
    lastName: "Test",
    email: "test@test.lt",
    roles: ["test"],
    username: "test",
    clients: dummyClients,
    clientIdList: dummyClients.map(item => item.id)
}

class LocalAuthService implements AuthService {
    logged = false
    login(emailUsername: string, password: string): Promise<boolean> {
        this.logged = true
        return new Promise<boolean>(resolve => resolve(true))
    }
    refresh(): Promise<boolean> {
        return new Promise<boolean>(resolve => resolve(true))
    }
    me(): Promise<UserData | null> {
        return new Promise<UserData | null>(resolve => resolve(this.logged ? dummyUserData : null))
    }
    logout(): Promise<boolean> {
        this.logged = false
        return new Promise<boolean>(resolve => resolve(true))
    }
    getClientsList(): Promise<Client[] | null> {
        return new Promise<Client[] | null>(resolve => resolve(this.logged ? dummyClients : null))
    }
}


export default new LocalAuthService()