import Section from 'components/Section';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { CircularProgress, FormControl, Grid, IconButton, LinearProgress, ListItemIcon, ListItemText, MenuItem, TextField, Dialog, DialogActions, Button, DialogTitle, Box } from '@mui/material';
import { AvailableTypes, Form, isFormValid, isElementConfigValid, ElementTypeEnum, ElementConfig, EmptyTypes } from 'lib/Form/Models';
import ElementConfigEdit from './ElementConfigEdit';
import { ReactSortable } from 'react-sortablejs';
import useDebounce from 'lib/Utils/debounce-hook';
import { Divider, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotesIcon from '@mui/icons-material/Notes';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EmailIcon from '@mui/icons-material/Email';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import deepCompare from 'lib/Utils/deep-compare';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { useParams } from 'react-router';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import TitleIcon from '@mui/icons-material/Title';
import BadgeIcon from '@mui/icons-material/Badge';
import useFormService from 'services/useFormService';
import { ClientContext } from 'lib/Auth/UserDataProvider';
import { useCallbackPrompt } from 'lib/Utils/useCallbackPromt';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import FormAnswerList from 'routes/FormAnswerList';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useSessionStorage from 'lib/Utils/use-session-storage';
import { Stack } from '@mui/system';

export type ElementConfigModification = (current: ElementConfig) => ElementConfig
export type ModifyFn = (fn: ElementConfigModification) => void

enum StatusState {
    SAVED,
    SAVING,
    EDITING,
    INCOMPLETE_DATA,
}

interface ElementTypeInfoProviderInterface {
    getIcon(type: ElementTypeEnum): FC
    getName(type: ElementTypeEnum): string
}


interface TempElementItem {
    id: number,
    type: ElementTypeEnum
}


enum FormEditTabEnum {
    INFO = "info",
    ANSWERS = "answers",
    STRUCTURE = "structure"
}

const FormEdit: FC = () => {
    const { client } = useContext(ClientContext)
    const service = useFormService()

    const [tab, setTab] = useSessionStorage<FormEditTabEnum>("form-edit-tab", FormEditTabEnum.INFO)

    const { t } = useTranslation('form-edit')
    const { id } = useParams()

    const initialForm = { id: "", name: "", elements: [], description: null, successText: null }
    const [form, setForm] = useState<Form>(initialForm)
    const [dataLoaded, setDataLoaded] = useState<boolean>(!id)

    const [savedForm, setSavedForm] = useState<Form>(initialForm)

    const [currentElementId, setCurrentElementId] = useState<string | null>(null)
    const [deleteConfirmId, setDeleteConfirmId] = useState<string | null>(null)

    const [statusState, setStatusState] = useState<StatusState>(StatusState.SAVED)
    const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(statusState !== StatusState.SAVED)

    const [pendingElements, setPendingElements] = useState<TempElementItem[]>([])

    const pendingSeq = useRef<number>(0)
    const version = useRef<number>(0)


    const openForm = (form: Form) => {
        window.open(`/form-submit/${form.id}`, '_blank', 'noopener,noreferrer');
    }

    const save = useDebounce(async (form: Form) => {
        if (!isFormValid(form)) {
            setStatusState(StatusState.INCOMPLETE_DATA)
            return
        }
        if (!deepCompare(form, savedForm)) {
            if (!service || !client) {
                return
            }
            const newVersion = version.current
            setStatusState(StatusState.SAVING)
            try {
                const result = await service.saveForm(form, client.id)
                if (newVersion !== version.current) {
                    return //version changed - abort
                }
                setSavedForm(result)
                setForm(result)
            } catch (e) {
                //TODO status error
                return;
            }
        }

        setStatusState(StatusState.SAVED)
    }, 1000)

    useEffect(() => {
        if (service && client) {
            const fetchForm = async (id: string) => {
                const result = await service.getForm(id)
                setForm(result)
                setDataLoaded(true)
            }
            if (id) {
                fetchForm(id)
            }
        }
    }, [service, id, client])

    if (!dataLoaded) {
        return <LinearProgress />
    }

    if (!form) {
        return <></>
    }


    const ElementTypeInfoProvider: ElementTypeInfoProviderInterface = {
        getIcon: (type: ElementTypeEnum) => {
            switch (type) {
                case ElementTypeEnum.Text:
                    return NotesIcon
                case ElementTypeEnum.Dropdown:
                    return FormatListBulletedIcon
                case ElementTypeEnum.Radio:
                    return RadioButtonCheckedIcon
                case ElementTypeEnum.Checkbox:
                    return CheckBoxIcon
                case ElementTypeEnum.File:
                    return AttachFileIcon
                case ElementTypeEnum.Date:
                    return DateRangeIcon
                case ElementTypeEnum.Email:
                    return EmailIcon
                case ElementTypeEnum.Header:
                    return TitleIcon
                case ElementTypeEnum.PageBreak:
                    return ContentCutIcon
                case ElementTypeEnum.EmploymentHistory:
                    return BadgeIcon
            }
        },

        getName: (type: ElementTypeEnum) => {
            switch (type) {
                case ElementTypeEnum.Text:
                    return t("ELEMENT_TYPE_TEXT")
                case ElementTypeEnum.Dropdown:
                    return t("ELEMENT_TYPE_DROPDOWN")
                case ElementTypeEnum.Radio:
                    return t("ELEMENT_TYPE_RADIO")
                case ElementTypeEnum.Checkbox:
                    return t("ELEMENT_TYPE_CHECKBOX")
                case ElementTypeEnum.File:
                    return t("ELEMENT_TYPE_FILE")
                case ElementTypeEnum.Date:
                    return t("ELEMENT_TYPE_DATE")
                case ElementTypeEnum.Email:
                    return t("ELEMENT_TYPE_EMAIL")
                case ElementTypeEnum.Header:
                    return t("ELEMENT_TYPE_HEADER")
                case ElementTypeEnum.PageBreak:
                    return t("ELEMENT_TYPE_PAGE_BREAK")
                case ElementTypeEnum.EmploymentHistory:
                    return t("ELEMENT_TYPE_EMPLOYMENT_HISTORY")
            }
        }
    }



    const setFormWithSave = (newState: (oldForm: Form) => Form) => {
        version.current++
        setStatusState(StatusState.EDITING)
        save(newState(form))

        return setForm(newState)
    }

    const currentConfig = currentElementId !== null ? form.elements.find(q => q.id === currentElementId) : null

    const setElements: React.SetStateAction<ElementConfig[]> = (elements: ElementConfig[]) => {
        setFormWithSave(old => ({
            ...old,
            elements: elements
        }))
        return form.elements
    }

    const deleteElement = (id: string) => {
        setDeleteConfirmId(null)
        setElements(form.elements.filter(item => item.id !== id))
    }


    const modifyCurrent: ModifyFn = async (fn: ElementConfigModification) => {
        setFormWithSave(old => ({
            ...old,
            elements: old.elements.map(element => {
                if (element.id === currentElementId) {
                    return fn(element)
                }
                return element
            })
        }))
    }

    const statusStateText = (state: StatusState): string => {
        switch (state) {
            case StatusState.SAVED:
                return t("STATUS_SAVED")
            case StatusState.SAVING:
                return t("STATUS_SAVING")
            case StatusState.EDITING:
                return t("STATUS_EDITING")
            case StatusState.INCOMPLETE_DATA:
                return t("STATUS_INCOMPLETE_DATA")
        }
    }

    const statusStateColor = (state: StatusState) => {
        switch (state) {
            case StatusState.SAVED:
                return "success"
            case StatusState.EDITING:
            case StatusState.SAVING:
                return "info"
            case StatusState.INCOMPLETE_DATA:
                return "error"
        }
    }


    const addElement = async (type: ElementTypeEnum) => {
        if (!service) {
            return
        }
        const pendingId = pendingSeq.current++
        setPendingElements(list => [...list, { id: pendingId, type: type }])
        try {
            const newElement = await service.createElement(type)
            setFormWithSave(old => ({
                ...old,
                elements: [...old.elements, newElement]
            }))
            if (newElement.type !== ElementTypeEnum.PageBreak) {
                setCurrentElementId(newElement.id)
            }
        } catch (error) {
            //TODO handle error
        }
        setPendingElements(list => list.filter(item => item.id !== pendingId))
    }

    let elementNumber = 0

    return <>
        <Dialog open={showPrompt} aria-labelledby="responsive-dialog-title">
            <DialogTitle>
                {t("FORM_EDITED_EXIT_CONFIRM_TEXT")}
            </DialogTitle>
            <DialogActions>
                <Button onClick={() => confirmNavigation()}>
                    {t("FORM_EDITED_EXIT_CONFIRM_YES")}
                </Button>
                <Button color={'primary'} onClick={() => cancelNavigation()}>
                    {t("FORM_EDITED_EXIT_CONFIRM_NO")}
                </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={deleteConfirmId !== null} onClose={() => setDeleteConfirmId(null)}>
            <DialogTitle>
                {t("DELETE_CONFIRM_MESSAGE")}
            </DialogTitle>
            <DialogActions>
                <Button variant="outlined" onClick={() => setDeleteConfirmId(null)}>{t("DELETE_CONFIRM_NO")}</Button>
                <Button variant="contained" color="primary" onClick={() => deleteConfirmId && deleteElement(deleteConfirmId)} autoFocus>{t("DELETE_CONFIRM_YES")}</Button>
            </DialogActions>
        </Dialog>

        <TabContext value={tab}>
            <Section>
                <Stack direction="row">
                    <TabList style={{ flex: 1 }} onChange={(e, value) => setTab(value)}>
                        <Tab label={t("FORM_TAB_INFO")} value={FormEditTabEnum.INFO} />
                        <Tab label={t("FORM_TAB_STRUCTURE")} value={FormEditTabEnum.STRUCTURE} />
                        {form.id && <Tab label={t("FORM_TAB_ANSWERS")} value={FormEditTabEnum.ANSWERS} />}
                    </TabList>
                    <Box>
                        {(statusState !== StatusState.SAVED || form.id) && [FormEditTabEnum.INFO, FormEditTabEnum.STRUCTURE].includes(tab) &&
                            <Chip color={statusStateColor(statusState)} label={statusStateText(statusState)} />
                        }
                        {form.id && <IconButton onClick={() => openForm(form)}><VisibilityIcon /></IconButton>}
                    </Box>
                </Stack>
            </Section>

            <TabPanel sx={{ paddingLeft: 0, paddingRight: 0 }} value={FormEditTabEnum.INFO}>
                <Section>
                    <Box style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        <FormControl fullWidth onClick={e => e.stopPropagation()}>
                            <TextField required error={statusState === StatusState.EDITING && form.name === ""} variant="standard" label={t("FORM_NAME_FIELD")} name="name" onChange={e => setFormWithSave(old => ({ ...old, [e.target.name]: e.target.value }))} value={form.name} />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField variant="standard" label={t("FORM_DESCRIPTION_FIELD")} name="description" onChange={e => setFormWithSave(old => ({ ...old, [e.target.name]: e.target.value }))} value={form.description ? form.description : ""} />
                        </FormControl>
                        <FormControl fullWidth>
                            <TextField variant="standard" label={t("FORM_SUCCESS_TEXT_FIELD")} name="successText" onChange={e => setFormWithSave(old => ({ ...old, [e.target.name]: e.target.value }))} value={form.successText ? form.successText : ""} />
                        </FormControl>
                    </Box>
                </Section>
            </TabPanel>
            <TabPanel sx={{ paddingLeft: 0, paddingRight: 0 }} value={FormEditTabEnum.STRUCTURE}>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Section>
                            {AvailableTypes.map((elementType, i) => {
                                const TypeIcon = ElementTypeInfoProvider.getIcon(elementType)

                                return <MenuItem key={i} onClick={() => addElement(elementType)}>
                                    <ListItemIcon>
                                        <TypeIcon />
                                    </ListItemIcon>

                                    <ListItemText primary={ElementTypeInfoProvider.getName(elementType)} />
                                </MenuItem>
                            })}
                        </Section>
                    </Grid>
                    <Grid item xs={4}>
                        <Section>
                            <ReactSortable
                                disabled={pendingElements.length > 0}
                                list={form.elements}
                                setList={(items: ElementConfig[]) => JSON.stringify(items) === JSON.stringify(form.elements) ? items : setElements(items)}
                            >{form.elements.map((element, i) => {
                                const TypeIcon = ElementTypeInfoProvider.getIcon(element.type)

                                const isPageBreak = element.type === ElementTypeEnum.PageBreak
                                const isHeading = element.type === ElementTypeEnum.Header


                                const hasNumber = !isHeading && !isPageBreak

                                if (hasNumber) {
                                    elementNumber++
                                }

                                const textStyle: React.CSSProperties = { overflow: "hidden", textOverflow: "ellipsis" }
                                if (isPageBreak) {
                                    textStyle.color = "#9f9f9f"
                                }
                                if (isHeading) {
                                    textStyle.fontWeight = "bold"
                                }

                                return <div key={element.id}><MenuItem selected={currentElementId === element.id} onClick={() => setCurrentElementId(element.id)}>
                                    <ListItemIcon>
                                        {hasNumber && elementNumber + "."}
                                    </ListItemIcon>

                                    <ListItemIcon>
                                        <TypeIcon />
                                    </ListItemIcon>

                                    <ListItemText primaryTypographyProps={{ style: textStyle }}>
                                        {isPageBreak ? t("ELEMENT_TYPE_PAGE_BREAK") : element.name}
                                    </ListItemText>


                                    {!isElementConfigValid(element) && <ReportProblemIcon color='error' />}
                                    <IconButton onClick={() => setDeleteConfirmId(element.id)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </MenuItem>{(i !== form.elements.length - 1 || pendingElements.length > 0) && <Divider />}</div>
                            })}
                            </ReactSortable>
                            {pendingElements.map((element, i) => {
                                const TypeIcon = ElementTypeInfoProvider.getIcon(element.type)

                                return <div key={i}><MenuItem>
                                    <ListItemIcon>
                                        ...
                                    </ListItemIcon>

                                    <ListItemIcon>
                                        <TypeIcon />
                                    </ListItemIcon>

                                    <CircularProgress size={20} />
                                </MenuItem>{i !== pendingElements.length - 1 && <Divider />}</div>
                            })}
                        </Section>
                    </Grid>
                    {currentConfig && !EmptyTypes.includes(currentConfig.type) &&
                        <Grid item xs={4}>
                            <Section>
                                <ElementConfigEdit key={currentConfig.id} t={t} currentConfig={currentConfig} modifyCurrent={modifyCurrent} />
                            </Section>
                        </Grid>}
                </Grid>
            </TabPanel>
            {form.id && <TabPanel sx={{ paddingLeft: 0, paddingRight: 0, paddingTop:1 }} value={FormEditTabEnum.ANSWERS}>
                <FormAnswerList formConfig={form} />
            </TabPanel>}
        </TabContext>
    </>
};

export default FormEdit;
