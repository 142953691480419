const locale = {
    //Table
    NEXT_STEP: "Toliau",
    PREVIOUS_STEP: "Atgal",

    REQUIRED_FIELD_LEFT_EMPTY_ERROR: "Laukelis privalomas",
    INVALID_EMAIL_FIELD_VALUE: "Nekorektiškas el. pašto adresas",


    EMPLOYER_LIST_MANDATORY: "Privaloma bent viena buvusi darbovietė",
    FROM_DATE_CANNOT_BE_LATER_THAN_TO_DATE: "Pabaigos data turi būti vėlesnė nei pradžios data",

    EMPLOYER_FORM_EMPLOYER_FIELD: "Darbovietės pavadinimas",
    EMPLOYER_FORM_ADDRESS_FIELD: "Adresas",
    EMPLOYER_FORM_PHONE_FIELD: "Telefono numeris",
    EMPLOYER_FORM_SUPERVISOR_FIELD: "Vadybininkas",
    EMPLOYER_FORM_POSITION_FIELD: "Pareigos",
    EMPLOYER_FORM_FROM_FIELD: "Nuo",
    EMPLOYER_FORM_TO_FIELD: "Iki",

    ADD_EMPLOYER: "Pridėti daugiau darboviečių",

    FORM_DEFAULT_SUCCESS_MESSAGE: "Forma pateikta",

    FILE_SELECT_FROM_STORAGE: "Įkelti",
    FILE_TAKE_PHOTO: "Kamera",
    CAMERA_ERROR: "Nepavyko pasiekti kameros. Patikrinkite leidimus.",
    FILE_FIELD_ATTACHED_FILE:"Prisegtas failas",

    SUBMIT_CONFIRM_MESSAGE: "Ar tikrai pateikti?",
    SUBMIT_CONFIRM_YES: "Taip",
    SUBMIT_CONFIRM_NO: "Ne",
};


export default locale