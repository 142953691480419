import { ErrorBoundary } from "@brunas/dashboard";
import { FC } from "react";
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

const UnauthorizedLayout: FC = () => {
    return <Box
    component="main"
    sx={{
      backgroundColor: theme =>
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[900],
      color: 'text.primary',
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      p: 2,
    }}>

    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  </Box>
};

export default UnauthorizedLayout;