import { FC, useState } from 'react';
import Dialog from '@mui/material/Dialog';

const Thumbnail: FC<{ url: string }> = ({ url }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return <>
    <img alt="" src={url} style={{ maxHeight: 70, cursor: "pointer" }} onClick={(e) => {
      setIsOpen(true)
      e.stopPropagation()
    }} />
    <Dialog open={isOpen} onClose={() => setIsOpen(false)}><img alt="" src={url} /></Dialog>
  </>
};

export default Thumbnail;
