import { FC, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Answer, ElementConfig, ElementTypeEnum, EmployerAnswerItem, Form, FormAnswer, FormAnswerStatusEnum } from 'lib/Form/Models';
import { useNavigate, useParams } from 'react-router';
import Section from 'components/Section';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import useFormService from 'services/useFormService';
import { isUrlImage } from 'lib/Utils/is-image';
import { Box, Dialog, Divider, FormControl, InputLabel, LinearProgress, MenuItem, Select, Stack, Typography } from '@mui/material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import useFormAnswersService from 'services/useFormAnswersService';
import ContentWrapper from 'components/ContentWrapper';
import DeleteIcon from '@mui/icons-material/Delete';
import { useConfirm } from 'components/ConfirmDialog';

const FormAnswerList: FC = () => {
    const { t } = useTranslation('form-answer-view')
    const { formId, answerId } = useParams()

    const formService = useFormService()
    const formAnswersService = useFormAnswersService()
    const navigate = useNavigate()
    const confirm = useConfirm()

    const [openDialog, setOpenDialog] = useState<string | null>(null)
    const [answer, setAnswer] = useState<FormAnswer | null>(null)
    const [formConfig, setFormConfig] = useState<Form | null>(null)

    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isStatusChanging, setIsStatusChanging] = useState<boolean>(false)

    const fetchFormData = useCallback(async (id: string) => {
        if (!formService) {
            return
        }
        const data = await formService.getForm(id)
        setFormConfig(data)
    }, [formService])

    const fetchAnswer = useCallback(async (formId: string, answerId: string) => {
        if (!formAnswersService) {
            return
        }
        const data = await formAnswersService.getAnswer(formId, answerId)
        setAnswer(data)
    }, [formAnswersService])

    const changeStatus = useCallback(async (formId: string, answer: FormAnswer, status: FormAnswerStatusEnum) => {
        setAnswer({ ...answer, status: status })
        setIsStatusChanging(true)
        try {
            await formAnswersService.changeStatus(formId, answer.id, status)
        } catch (e) {
            setAnswer({ ...answer, status: answer.status })
            //TODO error
        } finally {
            setIsStatusChanging(false)
        }
    }, [formAnswersService])

    useEffect(() => {
        if (formId) {
            fetchFormData(formId)
        }
    }, [formId, fetchFormData])

    useEffect(() => {
        if (formId && answerId) {
            fetchAnswer(formId, answerId)
        }
    }, [formId, answerId, fetchAnswer])


    const deleteAnswer = async (formId: string, answerId: string) => {
        if (await confirm({ message: t("DELETE_CONFIRM_TEXT") })) {
            try {
                setIsDeleting(true)
                await formAnswersService.deleteAnswer(formId, answerId)
                navigate("/form-edit/" + formId)
            } catch (e) {
                //TODO display error
            } finally {
                setIsDeleting(false)
            }
        }
    }

    const AnswerStatusTranslation = useCallback((status: FormAnswerStatusEnum): string => {
        switch (status) {
            case FormAnswerStatusEnum.New:
                return t("ANSWER_STATUS_NEW")
            case FormAnswerStatusEnum.Viewed:
                return t("ANSWER_STATUS_VIEWED")
            case FormAnswerStatusEnum.Confirmed:
                return t("ANSWER_STATUS_CONFIRMED")
            case FormAnswerStatusEnum.Cancelled:
                return t("ANSWER_STATUS_CANCELLED")
        }
    }, [t])

    if (!formConfig || !answer) {
        return <></>
    }


    const getAnswer = (elementId: string): Answer | null => {
        const result = answer.answers.find(item => item.elementId === elementId)
        return result ? result : null
    }

    const getAnswerValue = (elementConfig: ElementConfig, answerValueId: string): string | null => {
        const result = elementConfig.answerConfig?.values.find(item => item.id === answerValueId)
        return result ? result.value : null
    }

    const renderAnswer = (elementConfig: ElementConfig, answer: Answer): JSX.Element => {
        switch (elementConfig.type) {
            case ElementTypeEnum.File:
                if (!answer.value) {
                    return <></>
                }
                if (isUrlImage(answer.value)) {
                    return <>
                        <img alt="" src={answer.value} style={{ maxWidth: 200, cursor: "pointer" }} onClick={() => setOpenDialog(elementConfig.id)} />
                        <Dialog open={openDialog === elementConfig.id} onClose={() => setOpenDialog(null)}><img alt="" src={answer.value} /></Dialog>
                    </>
                }
                return <Button variant="text" LinkComponent={"a"} download href={answer.value} startIcon={<InsertDriveFileIcon />}>{t("DOWNLOAD_FILE")}</Button>

            case ElementTypeEnum.Date:
                return <span>{answer.value ? dayjs(answer.value).format("YYYY-MM-DD") : ""}</span>
            case ElementTypeEnum.Email:
            case ElementTypeEnum.Text:
                return <span>{answer.value}</span>
            case ElementTypeEnum.Dropdown:
            case ElementTypeEnum.Radio:
                return <span>{getAnswerValue(elementConfig, answer.value)}</span>
            case ElementTypeEnum.Checkbox:
                return <span>{answer.value.map((item: string) => getAnswerValue(elementConfig, item)).filter((x: string | null) => x).join(", ")}</span>
            case ElementTypeEnum.EmploymentHistory:
                if (!answer.value) {
                    return <></>
                }
                const cellStyle = { fontWeight: "bold" }
                return <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell style={cellStyle} component={"th"}>{t("EMPLOYER_FORM_EMPLOYER_FIELD")}</TableCell>
                            <TableCell style={cellStyle} component={"th"}>{t("EMPLOYER_FORM_FROM_FIELD")}</TableCell>
                            <TableCell style={cellStyle} component={"th"}>{t("EMPLOYER_FORM_TO_FIELD")}</TableCell>
                            <TableCell style={cellStyle} component={"th"}>{t("EMPLOYER_FORM_ADDRESS_FIELD")}</TableCell>
                            <TableCell style={cellStyle} component={"th"}>{t("EMPLOYER_FORM_PHONE_FIELD")}</TableCell>
                            <TableCell style={cellStyle} component={"th"}>{t("EMPLOYER_FORM_SUPERVISOR_FIELD")}</TableCell>
                            <TableCell style={cellStyle} component={"th"}>{t("EMPLOYER_FORM_POSITION_FIELD")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {answer.value.map((item: EmployerAnswerItem, i: number) => <TableRow key={i}>
                            <TableCell>
                                {item.employer}
                            </TableCell>
                            <TableCell>
                                {dayjs(item.from).format("YYYY-MM-DD")}
                            </TableCell>
                            <TableCell>
                                {item.to ? dayjs(item.to).format("YYYY-MM-DD") : "Dabar"}
                            </TableCell>
                            <TableCell>
                                {item.address}
                            </TableCell>
                            <TableCell>
                                {item.phone}
                            </TableCell>
                            <TableCell>
                                {item.supervisor}
                            </TableCell>
                            <TableCell>
                                {item.position}
                            </TableCell>
                        </TableRow>
                        )}
                    </TableBody>
                </Table>
            case ElementTypeEnum.Header:
            case ElementTypeEnum.PageBreak:
                return <></> //EMPTY
        }
    }

    let counter = 0;
    return <ContentWrapper width={1000}>
        {(isDeleting || isStatusChanging) && <LinearProgress />}
        <Section>
            <Stack direction={"row"} spacing={1} style={{ paddingBottom: 25 }}>
                <Typography style={{ flex: 1, fontWeight: "bold" }}>{formConfig.name}</Typography>
                <Button onClick={() => deleteAnswer(formConfig.id, answer.id)} variant='outlined'><DeleteIcon /></Button>
                <FormControl>
                    <InputLabel>{t("STATUS_CHANGE_LABEL")}</InputLabel>
                    <Select label={t("STATUS_CHANGE_LABEL")} value={answer.status} onChange={(e) => changeStatus(formConfig.id, answer, e.target.value as FormAnswerStatusEnum)}>
                        {Object.values(FormAnswerStatusEnum).map(value => <MenuItem key={value} value={value}>{AnswerStatusTranslation(value)}</MenuItem>)}
                    </Select>
                </FormControl>
            </Stack>
            <Stack direction={"column"} spacing={2}>
                {answer.schema.elements.map(element => {
                    const answer = getAnswer(element.id)

                    if (element.type === ElementTypeEnum.PageBreak) {
                        return <Divider />
                    }

                    if (element.type === ElementTypeEnum.Header) {
                        return <Typography key={element.id} style={{ fontWeight: "bold" }}>{element.name}</Typography>
                    }
                    counter++;
                    if (element.type === ElementTypeEnum.EmploymentHistory) {
                        return <>
                            <Typography>{counter}. {element.name}</Typography>
                            {answer ? renderAnswer(element, answer) : null}
                        </>
                    }

                    return <Stack style={{ width: "100%", justifyContent: "space-between" }} key={element.id} direction="row">
                        <Box style={{ width: "50%" }}>
                            <Typography>{counter}. {element.name}</Typography>
                        </Box>
                        <Box style={{ width: "50%" }}>
                            {answer ? renderAnswer(element, answer) : null}
                        </Box>
                    </Stack>
                })}
            </Stack>
        </Section>
    </ContentWrapper>
};

export default FormAnswerList;
