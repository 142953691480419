import { FC } from "react"
import { AnswerError, ErrorFieldType, PartialAnswerError } from "../FormView"
import SimpleQuestionFieldWrapper from "./SimpleQuestionFieldWrapper"
import { Answer, ElementConfig } from "lib/Form/Models"
import { MenuItem, Select } from '@mui/material';


type Props = {
  element: ElementConfig,
  error: AnswerError | null,
  answerData: Answer | null,
  updateValue: (value: any, clearFields: ErrorFieldType[]|null) => void
}


export const ValidateDropdownField = (answerData: Answer | null, elementConfig: ElementConfig, t: any): PartialAnswerError[] => {

  const value: string = answerData && answerData.value ? answerData.value : ""

  if (elementConfig.required) {
    if (!value) {
      return [{
        field: null,
        error: t("REQUIRED_FIELD_LEFT_EMPTY_ERROR")
      }]
    }
  }

  return []
}

const DropdownField: FC<Props> = ({ element, error, answerData, updateValue }) => {
  const answerConfig = element.answerConfig

  return <SimpleQuestionFieldWrapper element={element} error={error}>
    <Select value={answerData ? answerData.value : ""} onChange={(e) => updateValue(e.target.value, null)}>
      {answerConfig && answerConfig.values && answerConfig.values.map((value, i) => <MenuItem key={i} value={value.id ? value.id : ""}>{value.value}</MenuItem>)}
    </Select>
  </SimpleQuestionFieldWrapper>
}

export default DropdownField