import { FilesService } from "./FilesService"

class LocalFilesService implements FilesService {
    uploadFile(file: File): Promise<string> {
        return new Promise<string>(resolve => setTimeout(() => resolve(URL.createObjectURL(file)), 2000))
    }
    uploadContent(fileName:string, content: string): Promise<string> {
        return new Promise<string>(resolve => setTimeout(() => resolve(content), 2000))
    }
}


export default new LocalFilesService()