import { FC } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const MainToolbar: FC<{ appName: string }> = ({ appName }) => {

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Typography component="h1" variant="h6" sx={{ flexGrow: 1 }}>
        {appName}
      </Typography>
    </Box>
  );
};

export default MainToolbar;
