import dayjs, { Dayjs } from "dayjs"
import { Answer, ElementConfig, EmployerAnswerItem } from "lib/Form/Models"
import { FC, useState } from "react"
import { AnswerError, ErrorFieldType, PartialAnswerError } from "../FormView"
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import ListItemButton from '@mui/material/ListItemButton';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListItemIcon } from "@mui/material"
import DatePicker from "components/DatePicker";

type Props = {
    element: ElementConfig
    getError: (field: ErrorFieldType) => AnswerError | null
    answerData: Answer | null
    updateValue: (value: any, clearFields: ErrorFieldType[] | null) => void
    t: any,
    validate: () => boolean
}


const defaultAnswerItem: EmployerAnswerItem = {
    employer: "",
    from: "",
    to: null,
    address: "",
    phone: "",
    supervisor: "",
    position: "",
}

export const isAnswerEmpty = (item: EmployerAnswerItem): boolean => {
    return item.employer === ""
}

export const ValidateEmploymentHistoryField = (answerData: Answer | null, elementConfig: ElementConfig, t: any): PartialAnswerError[] => {

    const value: EmployerAnswerItem[] = answerData ? answerData.value : []
    const errors: PartialAnswerError[] = []


    const nonEmpty = value.filter(x => !isAnswerEmpty(x))
    //Checks if any employers entered
    if (elementConfig.required) {
        if (nonEmpty.length === 0) {
            errors.push({
                field: null,
                error: t("EMPLOYER_LIST_MANDATORY")
            })
            errors.push({
                field: "employer",
                error: t("REQUIRED_FIELD_LEFT_EMPTY_ERROR")
            })
            return errors
        }
    }

    //Checks every employer separately
    nonEmpty.forEach(item => {
        if (item.employer === "") {
            errors.push({
                field: "employer",
                error: t("REQUIRED_FIELD_LEFT_EMPTY_ERROR")
            })
        } else {
            if (item.from === "") {
                errors.push({
                    field: "from",
                    error: t("REQUIRED_FIELD_LEFT_EMPTY_ERROR")
                })
            }

            if (item.to && item.from >= item.to) {
                errors.push({
                    field: "to",
                    error: t("FROM_DATE_CANNOT_BE_LATER_THAN_TO_DATE")
                })
            }
        }
    })

    return errors
}

const EmploymentHistoryField: FC<Props> = ({ element, answerData, updateValue, t, getError, validate }) => {
    const answers: EmployerAnswerItem[] = answerData?.value ? answerData.value : [defaultAnswerItem]
    const [currentAnswer, setCurrentAnswer] = useState<number>(answers.length - 1)
    const currentAnswerData = answers[currentAnswer]


    const updateCurrentAnswer = (field: string, value: string | null): void => {
        const newAnswers = answers.map((item, i) => {
            if (i !== currentAnswer) {
                return item
            }

            return { ...item, [field]: value }
        })
        updateValue(newAnswers, [field, null])
    }

    const updateCompanyName = (value: string | null): void => {
        const newAnswers = answers.map((item, i) => {
            if (i !== currentAnswer) {
                return item
            }

            return { ...item, employer: value }
        })
        const clear = ['employer', null]
        if(value === ""){
            clear.push('from')
        }
        updateValue(newAnswers, clear)
    }

    const deleteAnswer = (index: number): void => {
        if (answers.length <= 1) {
            return //Not deletable
        }
        const indexAfterDelete = index > currentAnswer || index === 0 ? currentAnswer : currentAnswer - 1

        const newList = answers.filter((item, i) => i !== index)
        setCurrentAnswer(indexAfterDelete)
        updateValue(newList, null)
    }

    const addAnswer = () => {
        if (!validate()) {
            return
        }
        const newList = [...answers, defaultAnswerItem]
        updateValue(newList, null)
        setCurrentAnswer(newList.length - 1)
    }

    const changeCurrent = (index: number): void => {
        if (isAnswerEmpty(currentAnswerData)) { //if answer empty - delete and then change
            const indexAfterDelete = index > currentAnswer || index === 0 ? currentAnswer : currentAnswer - 1
            updateValue(answers.filter((x, i) => i !== currentAnswer), null)
            setCurrentAnswer(indexAfterDelete)
            return
        }

        //Validate current, if ok - change answer
        validate() && setCurrentAnswer(index)
    }

    const renderListItem = (answer: EmployerAnswerItem, index: number) => {
        return <ListItem divider secondaryAction={answers.length > 1 ? <IconButton onClick={() => deleteAnswer(index)}><DeleteIcon /></IconButton> : null} key={index}>
            <ListItemButton selected={index === currentAnswer} onClick={() => index !== currentAnswer ? changeCurrent(index) : null}>
                <ListItemIcon>{index + 1}.</ListItemIcon>
                <ListItemText primary={answer.employer ? answer.employer : "..."} />
            </ListItemButton>
        </ListItem>
    }


    const renderForm = (answer: EmployerAnswerItem) => {
        const employerError = getError("employer")
        const addressError = getError("address")
        const fromError = getError("from")
        const toError = getError("to")
        const phoneError = getError("phone")
        const supervisorError = getError("supervisor")
        const positionError = getError("position")

        return <Box style={{ gap: 10, display: "flex", flexDirection: "column" }}>
            <FormControl error={!!employerError} fullWidth>
                <FormLabel>{t("EMPLOYER_FORM_EMPLOYER_FIELD")} *</FormLabel>
                <TextField value={answer.employer} onChange={e => updateCompanyName(e.target.value)} />
                <FormHelperText>{employerError ? employerError.error : ""}</FormHelperText>
            </FormControl>
            {answer.employer !== "" && <>
            <FormControl error={!!fromError} fullWidth>
                <FormLabel>{t("EMPLOYER_FORM_FROM_FIELD")} *</FormLabel>
                <DatePicker value={answer.from ? dayjs(answer.from) : null} onChange={(item: Dayjs | null) => updateCurrentAnswer("from", item ? item.toISOString() : "")} />
                <FormHelperText>{fromError ? fromError.error : ""}</FormHelperText>
            </FormControl>
            <FormControl error={!!toError} fullWidth>
                <FormLabel>{t("EMPLOYER_FORM_TO_FIELD")}</FormLabel>
                <DatePicker value={answer.to ? dayjs(answer.to) : null} onChange={(item: Dayjs | null) => updateCurrentAnswer("to", item ? item.toISOString() : null)} clearable={true} />
                <FormHelperText>{toError ? toError.error : ""}</FormHelperText>
            </FormControl>
            <FormControl error={!!addressError} fullWidth>
                <FormLabel>{t("EMPLOYER_FORM_ADDRESS_FIELD")}</FormLabel>
                <TextField value={answer.address} onChange={e => updateCurrentAnswer("address", e.target.value)} />
                <FormHelperText>{addressError ? addressError.error : ""}</FormHelperText>
            </FormControl>
            <FormControl error={!!phoneError} fullWidth>
                <FormLabel>{t("EMPLOYER_FORM_PHONE_FIELD")}</FormLabel>
                <TextField value={answer.phone} onChange={e => updateCurrentAnswer("phone", e.target.value)} />
                <FormHelperText>{phoneError ? phoneError.error : ""}</FormHelperText>
            </FormControl>
            <FormControl error={!!supervisorError} fullWidth>
                <FormLabel>{t("EMPLOYER_FORM_SUPERVISOR_FIELD")}</FormLabel>
                <TextField value={answer.supervisor} onChange={e => updateCurrentAnswer("supervisor", e.target.value)} />
                <FormHelperText>{supervisorError ? supervisorError.error : ""}</FormHelperText>
            </FormControl>
            <FormControl error={!!positionError} fullWidth>
                <FormLabel>{t("EMPLOYER_FORM_POSITION_FIELD")}</FormLabel>
                <TextField value={answer.position} onChange={e => updateCurrentAnswer("position", e.target.value)} />
                <FormHelperText>{positionError ? positionError.error : ""}</FormHelperText>
            </FormControl>
            </>}
        </Box>
    }


    const mainError = getError(null)

    return <Box>
        <div style={{ paddingBottom: 10 }}>
            <label>{element.name}</label>
            {mainError && <FormHelperText error={true}>{t("EMPLOYER_LIST_MANDATORY")}</FormHelperText>}
        </div>
        <Divider />
        <List sx={{ 'li': { padding: 0, margin: 0 }, padding: 0 }}>
            {answers.map((answer, index) => renderListItem(answer, index))}
        </List>
        <div style={{ paddingTop: 10 }}>

            {renderForm(currentAnswerData)}
            <Button onClick={addAnswer}>{t("ADD_EMPLOYER")}</Button>
        </div>
    </Box>
}

export default EmploymentHistoryField