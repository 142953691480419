import { TextField as MuiTextField } from "@mui/material"
import { FC } from "react"
import { AnswerError, ErrorFieldType, PartialAnswerError } from "../FormView"
import SimpleQuestionFieldWrapper from "./SimpleQuestionFieldWrapper"
import { Answer, ElementConfig } from "lib/Form/Models"
import InputAdornment from '@mui/material/InputAdornment';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import isEmail from "lib/Utils/is-email"


type Props = {
  element: ElementConfig,
  error: AnswerError | null,
  answerData: Answer | null,
  updateValue: (value: any, clearFields: ErrorFieldType[] | null) => void
}


export const ValidateEmailField = (answerData: Answer | null, elementConfig: ElementConfig, t: any): PartialAnswerError[] => {

  const value: string = answerData && answerData.value ? answerData.value : ""

  if (elementConfig.required) {
    if (!value) {
      return [{
        field: null,
        error: t("REQUIRED_FIELD_LEFT_EMPTY_ERROR")
      }]
    }
  }

  if (value && !isEmail(value)) {
    return [{
      field: null,
      error: t("INVALID_EMAIL_FIELD_VALUE")
    }]
  }

  return []
}

const EmailField: FC<Props> = ({ element, error, answerData, updateValue }) => {
  return <SimpleQuestionFieldWrapper element={element} error={error}><MuiTextField InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <AlternateEmailIcon />
      </InputAdornment>
    ),
  }} onChange={(e) => updateValue(e.target.value, null)} value={answerData ? answerData.value : ""} /></SimpleQuestionFieldWrapper>
}

export default EmailField