import React, { createContext, FC, useCallback, useEffect, useState } from "react";
import useAuthService from "services/useAuthService";
import { Client, IsSuper, UserData } from "./Models";

export type UserContext = {
    userData: UserData | null,
    refreshData: () => void,
    loading: boolean
}

export type ClientContextProps = {
    client: Client | null,
    setClient: (client: Client) => void
    clientList: Client[]
}

export const UserDataContext = createContext<UserContext>({ userData: null, loading: false, refreshData: () => { } })
export const ClientContext = createContext<ClientContextProps>({ client: null, setClient: () => { }, clientList: [] })

const UserDataProvider: FC<{ children: React.ReactNode }> = ({ children }) => {

    const authService = useAuthService()

    const [userData, setUserData] = useState<UserData | null>(null)
    const [userDataLoading, setUserDataLoading] = useState<boolean>(true)
    const [client, setClient] = useState<Client | null>(null)
    const [clientList, setClientList] = useState<Client[]>([])

    const fetchUserData = useCallback(async () => {
        setUserDataLoading(true)
        const data = await authService.me()
        setUserDataLoading(false)
        setUserData(data)
    }, [authService])

    const updateClientList = useCallback(async (userData: UserData | null) => {
        if (!userData) {
            setClientList([])
            setClient(null)
            return
        }

        const clientList = IsSuper(userData) ? await authService.getClientsList() : userData.clients
        if (clientList && clientList.length > 0) {
            setClient(clientList[0])
        }
        setClientList(clientList ? clientList : [])
    }, [authService])

    useEffect(() => {
        updateClientList(userData)
    }, [userData, updateClientList])

    useEffect(() => {
        // login()
        fetchUserData()
    }, [fetchUserData])


    return <UserDataContext.Provider value={{ loading: userDataLoading, userData: userData, refreshData: fetchUserData }}>
        <ClientContext.Provider value={{ client: client, setClient: (client: Client) => setClient(client), clientList: clientList }}>
            {children}
        </ClientContext.Provider>
    </UserDataContext.Provider>
}


export default UserDataProvider