import { FilesService } from "lib/Files/FilesService";
import AuthorizedAxiosInstance from "./AuthorizedAxiosInstance";


type Response<T> = {
    status: number,
    errorCode: string | null,
    errorMessage: string | null,
    data: T
}

type Upload = {
    fullPath: string,
    fileName: string
}


class AuthApiService implements FilesService {
    //return a promise that resolves with a File instance
    async urltoFile(url: string, filename: string, mimeType: string): Promise<File> {
        return await (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    base64MimeType(encoded: string) {
        var result = null;

        var mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) {
            result = mime[1];
        }

        return result;
    }

    host: string = "/api/upload";
    async uploadContent(fileName: string, content: string): Promise<string> {
        const type = this.base64MimeType(content)
        if (type === null) {
            return Promise.reject()
        }

        return this.uploadFile(await this.urltoFile(content, fileName, type))
    }


    uploadFile(file: File): Promise<string> {
        const formData = new FormData();
        formData.append("file", file);
        return AuthorizedAxiosInstance.post<Response<Upload>>(`${this.host}/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(value => "https://upload.brunas.lt/read/" + value.data.data.fullPath)
    }

}

export default new AuthApiService()