import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material"
import { createContext, FC, useCallback, useContext, useRef, useState } from "react"
import { useTranslation } from "react-i18next"

type ConfirmDialogParams = {
    message: string
}
const ConfirmDialog = createContext<(params: ConfirmDialogParams) => Promise<boolean>>(() => new Promise<boolean>(resolve => resolve(true)))

export const ConfirmDialogProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    const { t } = useTranslation("confirm-dialog")

    const [open, setOpen] = useState<boolean>(false)
    const [message, setMessage] = useState<string>("")
    const fn = useRef<(choice: boolean) => void>(() => { })

    const confirm = useCallback<(params: ConfirmDialogParams) => Promise<boolean>>(({ message }) => {
        setOpen(true)
        return new Promise<boolean>((resolve) => {
            setMessage(message)
            fn.current = (choice) => {
                setOpen(false)
                resolve(choice)
            }
        })
    }, [setOpen])

    return <ConfirmDialog.Provider value={confirm}>
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>
                {message}
            </DialogTitle>
            <DialogActions>
                <Button variant="outlined" onClick={() => {
                    setOpen(false)
                    fn.current(false)
                }}>{t("CONFIRM_NO")}</Button>
                <Button variant="contained" color="primary" onClick={() => {
                    setOpen(false)
                    fn.current(true)
                }} autoFocus>{t("CONFIRM_YES")}</Button>
            </DialogActions>
        </Dialog>
        {children}
    </ConfirmDialog.Provider>
}

export function useConfirm() {
    return useContext(ConfirmDialog)
}