import { Client } from "lib/Auth/Models"
import { CountRequest, ListRequest } from "@brunas/react-mui-datatable"
import { FormService } from "lib/Form/FormService"
import { Form, ElementConfig, ElementTypeEnum } from "lib/Form/Models"
import AuthorizedAxiosInstance from "./AuthorizedAxiosInstance"


type Response<T> = {
    status: number,
    errorCode: string | null,
    errorMessage: string | null,
    data: T
}

export class FormApiService implements FormService {
    host: string = "/api/forms";


    //Datatable functions
    getDatatableList(request: ListRequest, config: Client): Promise<Form[]> {
        const result = AuthorizedAxiosInstance.post<Response<Form[]>>(`${this.host}/datatable?client_id=${config.id}`, request).then(value => value.data.data)

        return result
    }
    getDatatableCount(request: CountRequest, config: Client): Promise<number> {
        const result = AuthorizedAxiosInstance.post<Response<number>>(`${this.host}/datatable/count?client_id=${config.id}`, request).then(value => value.data.data)

        return result
    }

    deleteForm(id: string): Promise<boolean> {
        const result = AuthorizedAxiosInstance.delete<Response<null>>(`${this.host}/${id}`).then(value => true)
        return result
    }

    async createElement(type: ElementTypeEnum): Promise<ElementConfig> {
        return AuthorizedAxiosInstance.post<Response<ElementConfig>>(`${this.host}/elements?type=${type.toString()}`).then(value => value.data.data)
    }

    async saveForm(form: Form, clientId: string): Promise<Form> {
        if (form.id) {
            return AuthorizedAxiosInstance.put<Response<Form>>(`${this.host}/${form.id}`, form).then(value => value.data.data)
        }
        return AuthorizedAxiosInstance.post<Response<Form>>(`${this.host}?client_id=${clientId}`, form).then(value => value.data.data)
    }

    getForm(id: string): Promise<Form> {

        const result = AuthorizedAxiosInstance.get<Response<Form>>(`${this.host}/${id}`).then(value => value.data.data)

        return result
    }
}

export default new FormApiService()