const locale = {
    //Table
    FORMS_TABLE_TITLE:"Form list",
    TABLE_COLUMN_NAME: "Title",
    TABLE_COLUMN_ACTIONS: "Actions",
    NO_FORMS_IN_LIST: "Form list is empty",
    //Table actions
    TABLE_ACTION_PREVIEW: "Preview",
    TABLE_ACTION_ANSWERS: "Answers list",
    TABLE_ACTION_EDIT: "Edit",
    TABLE_ACTION_DELETE: "Delete",

    DELETE_CONFIRM_MESSAGE: "Are you sure?",
    DELETE_CONFIRM_YES: "Yes",
    DELETE_CONFIRM_NO: "No",
};


export default locale