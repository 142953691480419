import { FC, useContext } from 'react';
import { ClientContext } from 'lib/Auth/UserDataProvider';
import { LinearProgress } from '@mui/material';
import FormDatatable from './FormDatatable';


const FormList: FC = () => {
    const { client } = useContext(ClientContext)

    if (!client) {
        return <LinearProgress />
    }

    return <FormDatatable client={client} />
};

export default FormList;
