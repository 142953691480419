import { Form, ElementTypeEnum, ElementConfig, Answer, AnswerTableItem, FormAnswer, WebCam } from "./Models";
import { FormService } from "./FormService";
import { CountRequest, ListRequest } from "@brunas/react-mui-datatable"
import { Client } from "lib/Auth/Models";

const data: Form = {
    id: "xxx",
    description: "Short form description. Don't like? Don't read",
    name: "First form",
    successText: null,
    elements: [
        {
            id: "1",
            name: "Name",
            description: "",
            type: ElementTypeEnum.Text,
            showInTable: false,
            answerConfig: {
                camera: null,
                values: [],
                rowCount: 1
            },
            required: true
        },
        {
            id: "2",
            name: "Email",
            description: "",
            type: ElementTypeEnum.Email,
            showInTable: false,
            required: true,
            answerConfig: null
        },
        {
            id: "3",
            name: "Photo",
            description: "",
            showInTable: false,
            type: ElementTypeEnum.File,
            required: true,
            answerConfig: {
                camera: WebCam.Front,
                values: [],
                rowCount: 1
            }
        },
        {
            id: "4",
            name: "Life story",
            description: "Write a short life story",
            type: ElementTypeEnum.Text,
            showInTable: false,
            answerConfig: {
                camera: null,
                values: [],
                rowCount: 3
            },
            required: false
        },
        {
            id: "5",
            name: "Age",
            description: "",
            type: ElementTypeEnum.Dropdown,
            showInTable: false,
            answerConfig: {
                camera: null,
                rowCount: 1,
                values: [
                    {
                        id: "1",
                        value: "10"
                    },
                    {
                        id: "2",
                        value: "20"
                    },
                    {
                        id: "3",
                        value: "30"
                    }
                ]
            },
            required: true
        },
        {
            id: "6",
            name: "Gender",
            description: "",
            type: ElementTypeEnum.Radio,
            showInTable: false,
            answerConfig: {
                camera: null,
                rowCount: 1,
                values: [
                    {
                        id: "1",
                        value: "Female"
                    },
                    {
                        id: "2",
                        value: "Male"
                    },
                    {
                        id: "3",
                        value: "Other"
                    }
                ]
            },
            required: true
        },
        {
            id: "7",
            name: "Your favorite foods",
            description: "",
            type: ElementTypeEnum.Checkbox,
            showInTable: false,
            answerConfig: {
                camera: null,
                rowCount: 1,
                values: [
                    {
                        id: "1",
                        value: "Soup"
                    },
                    {
                        id: "2",
                        value: "Kotlets"
                    },
                    {
                        id: "3",
                        value: "Kebab"
                    }
                ]
            },
            required: false
        }
    ]
}


class LocalFormService implements FormService {
    startId: number
    constructor() {
        this.startId = 99999; //dummy number to start id generation
    }
    getDatatableList(request: ListRequest, config: Client): Promise<Form[]> {
        return new Promise<Form[]>(resolve => setTimeout(() => resolve([data]), 500))
    }
    getDatatableCount(request: CountRequest, config: Client): Promise<number> {
        return new Promise<number>(resolve => setTimeout(() => resolve([data].length), 500))
    }

    getFormsDatatableList(clientId: string, query: string, page: number, pageSize: number): Promise<Form[]> {
        return new Promise<Form[]>(resolve => setTimeout(() => resolve([data]), 500))
    }

    getFormsDatatableCount(clientId: string, query: string): Promise<number> {
        return new Promise<number>(resolve => setTimeout(() => resolve([data].length), 500))
    }

    getAnswer(formId: string, answerId: string): Promise<FormAnswer | null> {
        return new Promise<FormAnswer | null>(resolve => setTimeout(() => resolve(null), 500))
    }
    getAnswerTableList(id: string, limit: number, page: number): Promise<AnswerTableItem[]> {
        return new Promise<AnswerTableItem[]>(resolve => setTimeout(() => resolve([]), 500))
    }
    deleteForm(id: string): Promise<boolean> {
        return new Promise<boolean>(resolve => setTimeout(() => resolve(true), 500))
    }

    getNextId(): string {
        return (this.startId++).toString()
    }

    async createElement(type: ElementTypeEnum): Promise<ElementConfig> {
        return new Promise<ElementConfig>(resolve => setTimeout(async () => {
            const basicElement = {
                id: this.getNextId(),
                type: type,
                required: false,
                showInTable: false,
                name: "",
                description: ""
            }
            switch (type) {
                case ElementTypeEnum.Text:
                    resolve({
                        ...basicElement,
                        answerConfig: {
                            camera: null,
                            rowCount: 1,
                            values: [],
                        }
                    })
                    break;
                case ElementTypeEnum.Dropdown:
                case ElementTypeEnum.Checkbox:
                case ElementTypeEnum.Radio:
                    resolve({
                        ...basicElement,
                        answerConfig: {
                            camera: null,
                            rowCount: 0,
                            values: [
                            ]
                        }
                    })
                    break;
                case ElementTypeEnum.File:
                    resolve({
                        ...basicElement,
                        answerConfig: {
                            camera: WebCam.Front,
                            values: [],
                            rowCount: 1
                        }
                    })
                    break;
                case ElementTypeEnum.Email:
                case ElementTypeEnum.Date:
                case ElementTypeEnum.PageBreak:
                case ElementTypeEnum.Header:
                    resolve({
                        ...basicElement,
                        answerConfig: null
                    })
                    break;
            }
        }, 200))

    }

    async saveForm(form: Form, clientId: string): Promise<Form> {
        return new Promise<Form>(resolve => setTimeout(() => resolve({
            ...form,
            elements: form.elements.map(element => ({
                ...element,
                answerConfig: element.answerConfig ? {
                    ...element.answerConfig,
                    values: element.answerConfig?.values?.filter(x => x.value !== "").map(value => ({ ...value, id: value.id ? value.id : this.getNextId() }))
                } : null
            }))
        }), 500))
    }

    getForm(id: string): Promise<Form> {
        return new Promise<Form>(resolve => setTimeout(() => resolve(data), 500))
    }

    submitAnswer(id: string, answers: Answer[]): Promise<boolean> {
        return new Promise<boolean>(resolve => resolve(true))
    }
}

export default new LocalFormService()